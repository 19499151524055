<template>
    <flex-layout>
        <AuctionShareModal :companies="companies" />
        <AuctionShareDataModal :companies="companies" />
        <page-header heading="Auction Share Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="auction_share_list.data"
                    :columns="auction_share_list.columns" file-name="auctionsharetemplate" :file-type="'xlsx'"
                    sheet-name="AuctionShareTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>

        </page-header>

        <AuctionShareTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import AuctionShareTable from "@/components/AuctionShare/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import AuctionShareModal from "@/components/AuctionShare/modal.vue";
import AuctionShareDataModal from "@/components/AuctionShare/datamodal.vue";

export default {
    name: "AuctionShareCheck",
    components: {
        PageHeader,
        FlexLayout,
        AuctionShareTable,
        AuctionShareModal,
        AuctionShareDataModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'auction-share-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Boid",
                },
                {
                    heading: "Allotted Kitta",
                },
                {
                    heading: "Company",
                },
            ],
            auction_share_list: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "allotted_kitta",
                        field: "allotted_kitta",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        allotted_kitta: '123'
                    },
                ],
            },
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'company/auction-share-check';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
