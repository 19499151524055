<template>
  <div class="app-wrapper-layout">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WrapperLayout",
};
</script>