<template>
    <flex-layout>
        <page-header heading="News Letters">
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>
        </page-header>
        <NewsLetterTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import NewsLetterTable from "@/components/NewsLetter/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
export default {
    name: "NewsLetters",
    components: {
        PageHeader,
        FlexLayout,
        NewsLetterTable,
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'news-letter',
            tableHeading: [
                {
                    heading: "Email",
                },
                {
                    heading: "Phone Number",
                },
                {
                    heading: 'Subscribed on'
                }
            ],
        };
    },

};
</script>
  