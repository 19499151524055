
<template>
    <flex-layout>
        <page-header heading="Career Applications" toggle="offcanvas" target="#offcanvasCreate" >
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export Data</button>
                </a>
            </template>

            <template v-slot:example>
                <a :href="uri + '/export/files'">
                    <button class="btn btn-sm btn-outline-primary"><i class="ic-checklist"></i>Export Files</button>
                </a>
            </template>
        </page-header>
        <CareerApplicationTable :categories="categories" :tableHeading="tableHeading"/>
    </flex-layout>

</template>

<script>
import PageHeader from "@/components/Utility/PageHeader.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CareerApplicationTable from "@/components/CareerApplications/table.vue";
export default {
    name: 'CareerApplications',
    components: { PageHeader, FlexLayout,CareerApplicationTable },

    data() {
        return {
            uri: process.env.VUE_APP_API_URL + "careerapplications",
            tableHeading: [
                {
                    heading: "Name",
                },
                { 
                    heading:"Applied For"
                },
                {
                    heading: "Marks Obtained/CGPA",
                },
                {
                    heading: "Submitted On",
                },
                {
                    heading: "Action"
                }
            ],
            categories: [],

        };
    },

    mounted() {
         this.fetchCareerCategories();
    },

    methods: {
        async fetchCareerCategories() {
            let uri = process.env.VUE_APP_API_URL + 'career-openings/categories';
            this.isLoading = true
            try {
                this.axios.get(uri, { params: this.params }).then(({ data }) => {
                    this.categories = data;
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
    },
};
</script>

