<template>
  <div class="d-flex mb-16">
    <div class="dropdown dropdown-01">
      <button class="btn btn-outline-primary btn-icon" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="ic-sort mr-4"></i>Sort
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <p class="font-semibold mb-8 text-black">Sort By</p>
        <li v-for="(item, key) in sorts" :key="item.key" @click="sortBy(key)">
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="dropdown dropdown-01 filter pl-12">
      <button class="btn btn-primary btn-icon" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="ic-follow"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <p class="font-semibold mb-8 text-black">Filter By Position</p>
        <form>
          <div class="mb-16">
            <label for="" class="small">Position</label>
            <input type="text" class="form-control form-control-sm" v-model="params.filters.position" />
          </div>
        </form>
        <div class="d-flex">
          <button class="btn btn-sm btn-outline-red btn-block mr-16" @click="resetFilters()">
            Cancel
          </button>
        </div>
      </ul>
    </div>
    <div class="form-icon trail mx-16 flex-grow-1">
      <input class="form-control" placeholder="Search" v-model="params.search" /><i class="ic-search"></i>
    </div>
    <div class="dropdown dropdown-01 filter">
      <button class="btn btn-primary btn-icon" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="ic-filter"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <p class="font-semibold mb-8 text-black">Filter By</p>
        <form>
          <div class="mb-12">
            <label for="" class="small">Date From</label>
            <input type="date" class="form-control form-control-sm" v-model="params.filters.from_date" />
          </div>
          <div class="mb-16">
            <label for="" class="small">Date To</label>
            <input type="date" class="form-control form-control-sm" v-model="params.filters.to_date" />
          </div>
        </form>
        <div class="d-flex">
          <button class="btn btn-sm btn-outline-red btn-block mr-16" @click="resetFilters()">
            Cancel
          </button>
        </div>
      </ul>
    </div>
    <div class="ml-12 dropdown dropdown-01">
      <button class="btn btn-primary btn-icon" id="dropdownMenuButtonExportCat" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="ic-export"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonExportCat">
        <p class="font-semibold mb-8 text-black">Export Files for:</p>
        <li v-for="(category, index) in categories" :key="index">
          <label class="checkbox-label">
            <input type="checkbox" v-model="selectedExportCategories" :value="category.id" /> {{ category.position }}
          </label>
        </li>
        <div class="d-flex">
          <a :href="uri + `/files?career_opening_ids=[${this.selectedExportCategories}]`" class="mr-16 btn-sm btn-primary btn-block">
            <button class="btn btn-sm btn-primary btn-block" @click="exportSelectedCategories">Export</button>
          </a>
          <button class="btn btn-sm btn-outline-red btn-block" @click="resetSelectedCategories()">
            Cancel
          </button>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'TableQuery',
        data() {
            return {
                selectedExportCategories: [],
                uri: process.env.VUE_APP_API_URL + "careerapplications/export-category",

            };
        },
        props: {
            'resetFilters': {
                type: Function
            },
            'sortBy': {
                type: Function
            },
            'sorts': {
                type: Object
            },
            'params': {
                type: Object
            },
            'categories': {
                type: Array
            }
        },
        methods: {
            exportSelectedCategories() {
                    let uri = process.env.VUE_APP_API_URL + uri;
                    this.isLoading = true
                    try {
                        this.axios.post(uri, {
                            params: {
                                career_opening_ids: this.selectedExportCategories
                            }
                        }).then(({
                            data
                        }) => {
                            console.log("data", data);
                        });
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.isLoading = false
                    }
            },
            resetSelectedCategories() {
                console.log("resetting");
                this.selectedExportCategories = [];
            }
        },
    }; 
</script>

<style scoped>
.checkbox-label {
  display: block;
  margin-bottom: 
