<template>
    <flex-layout>
        <MFListingModal :menus="menus"/>
        <page-header heading="Mutual Fund Listing Content" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <MFListingTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import MFListingTable from "@/components/MutualFund/Listing/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import MFListingModal from "@/components/MutualFund/Listing/modal.vue";
export default {
    name: "MFListingContent",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        MFListingTable,
        MFListingModal
    },
    data() {
        return {
           menus: {},
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Menu",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },

    async created(){
        this.fetchListingMenus();
    },

    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        async fetchListingMenus() {
            let url = process.env.VUE_APP_API_URL + 'mutual-fund-menu/getMenus/2';
            let {data} = await this.axios.get(url);
            this.menus = data.menus;
        }
    }
};
</script>
  