<template>
    <flex-layout>
        <IpoResultModal :companies="companies" />
        <IpoResultDataModal :companies="companies" />
        <page-header heading="IPO Result Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="ipo_result.data"
                    :columns="ipo_result.columns" file-name="iporesulttemplate" :file-type="'xlsx'"
                    sheet-name="IpoResultTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>

        </page-header>

        <IpoResultTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import IpoResultTable from "@/components/IpoResult/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import IpoResultModal from "@/components/IpoResult/modal.vue";
import IpoResultDataModal from "@/components/IpoResult/datamodal.vue";

export default {
    name: "IpoResultCheck",
    components: {
        PageHeader,
        FlexLayout,
        IpoResultTable,
        IpoResultModal,
        IpoResultDataModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'ipo-result-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Boid",
                },
                {
                    heading: "Allotted Kitta",
                },
                {
                    heading: "Company",
                },
            ],
            ipo_result: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "allotted_kitta",
                        field: "allotted_kitta",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        allotted_kitta: '123'
                    },
                ],
            },
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'company/ipo-result-check';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
