<template>
    <flex-layout>
        <ServiceLinkModal />
        <page-header heading="Service Links" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />

        <ServiceLinkTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import ServiceLinkTable from "@/components/ServiceLink/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import ServiceLinkModal from "@/components/ServiceLink/modal.vue";
export default {
    name: "ServiceFAQ",
    components: {
        PageHeader,
        FlexLayout,
        ServiceLinkTable,
        ServiceLinkModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Product/Service",
                },
                {
                    heading: "Title",
                },
                {
                    heading: "Subtitle",
                },
                {
                    heading: "Link",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  