<template>
  <tr>
    <td colspan="6" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
    name: 'Spinner',
};
</script>