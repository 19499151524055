<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Uncollected Cash Dividend Check</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">Company Name</label>
                        <select name="company_id" class="form-select" :class="{ 'border-red': errors.company_id }"
                            v-model="item.company_id">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat, key) in companies" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>


                    <div class="col-md-12 mb-16">
                        <label for="">Fiscal Year</label>
                        <select name="type" class="form-select" :class="{ 'border-red': errors.fiscal_years }"
                            v-model="item.fiscal_years">
                            {{ fiscal_years }}
                            <option value="" disabled selected>Select Fiscal Year</option>

                            <option v-for="(item, index) in fiscal_years" :key="index" :value="item"> {{ item }}
                            </option>
                        </select>
                        <span v-if="errors.fiscal_years"
                            class="text-danger mt-2 font-size-13">{{ errors.fiscal_years }}</span>
                    </div>

                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">Upload file</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload"
                                accept=".xls,.xlsx" />
                            <ErrorSpan :error="errors.file" />
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "UncollectedCashDividendCheckModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'uncollected-cash-dividend-check',
            mode: "",
            fetch_module: 'FETCH_UNCOLLECTED_CASHs',
            fiscal_years: [
                '2066/67',
                '2067/68',
                '2068/69',
                '2069/70',
                '2070/71',
                '2071/72',
                '2072/73',
                '2073/74',
                '2074/75',
                '2075/76',
                '2076/77',
                '2077/78',
                '2078/79',
                '2079/80',
                '2080/81',
                '2081/82',
                '2082/83',
                '2083/84',
                '2084/85',
                '2085/86',
                '2086/87',
                '2087/88',
                '2088/89',
                '2089/90',
                '2090/91',
                '2091/92',
                '2092/93',
                '2093/94',
                '2094/95',
                '2095/96',
                '2096/97',
                '2097/98',
                '2098/99',
            ]
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
            };
            this.errors = {};
            this.mode = "Add";
            this.clearSelectedFile();
        });

        this.$root.$on('EDIT', (data) => {
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
            this.clearSelectedFile();
        });

    },
    methods: {
        handleFileUpload(e) {
            const [file] = e.target.files;
            this.item.file = file;
        },
        clearSelectedFile() {
            if (this.$refs.file) {
                this.$refs.file.value = "";
            }
        }
    },
};
</script>

<style scoped>

</style>
