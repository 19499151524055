<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasEditGallery" tabindex="-1"
        aria-labelledby="offcanvasEditGallery">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasEditGallery">{{ mode }} Gallery Data</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Title</label>
                        <input type="text" class="form-control" :class="{ 'border-red': errors.title }"
                            v-model="item.title" />
                        <ErrorSpan :error="errors.title" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Caption</label>
                        <input type="text" class="form-control" :class="{ 'border-red': errors.caption }"
                            v-model="item.caption" />
                        <ErrorSpan :error="errors.caption" />
                    </div>
                    <div class="col-md-12 mb-16">
                        <div class="textbox-04">
                            <label for="">Image</label>

                            <div class="col-md-26 mb-6">
                                <div class="row">
                                    <div class="col-lg-8">
                                        <div class="form-group">
                                            <div>
                                                <input class="form-control" type="file" ref="uploadFile" multiple="multiple"
                                                    @input="handleFileUpload" accept="image/*" />
                                            </div>
                                            <ErrorSpan :error="errors.image" />
                                            <div v-if="this.image.length + this.imagePreview.length > 0"
                                                class="position-relative m-4">
                                                <button @click.prevent="clearAll"
                                                    class="btn btn-icon btn-danger cropper-close-btn flex-between ">
                                                    Clear All <i class="ic-close ml-24"></i>
                                                </button>
                                                <p class="text-danger">
                                                    * {{ this.imagePreview.length > 0 ? this.imagePreview.length +
                                                        this.image.length : this.image.length }} Image Selected
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="card-02 pb-0 mt-3" v-for=" (item, index) in this.image" :key="index">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="textbox-04">
                                    <span>
                                        <i class="ic-close" @click.prevent="clearImage(index, type = 'mounted')"></i>
                                    </span>

                                    <img :src="storage + 'galleries/' + item" alt="" style="height: 100%; width: 100%;"
                                        class="resize mx-md-6 mb-16 mt-12">

                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="imagePreview.length > 0">
                        <div class="card-02 pb-0 mt-3" v-for=" (item, index) in filePreview" :key="'A' + index">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="textbox-04">
                                        <span>
                                            <i class="ic-close" @click.prevent="clearImage(index, type = 'preview')"></i>
                                        </span>
                                        <img :src="item" alt="" style="height: 100%; width: 100%;"
                                            class="resize mx-md-6 mb-16 mt-12">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="col-md-6 mb-16">
                        <label for="">Album</label>
                        <select name="album_id" class="form-select" v-model="item.album_id">
                            <option value="" selected>Select Parent</option>
                            <option v-for="item in albums" :key="item.id" :value="item.id">{{ item.title }}</option>
                        </select>
                        <ErrorSpan :error="errors.album_id" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
                            class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
                        <ErrorSpan :error="errors.order_by" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" @click="submitForm()">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";

export default {
    name: "GalleryEditModal",
    mixins: [CreateMixin, UploadPreviewMixin, MediasMixin],
    components: { ErrorSpan },
    data() {
        return {
            url: 'gallery',
            mode: "",
            fetch_module: 'FETCH_GALLERIES',
            item_struct: {
                id: "",
                title: "",
                caption: "",
                image: "",
                order_by: 1,
                status: true,
                album_id: "",
            },
            albums: {},
            image: [],
            imagePreview: [],
            filePreview: [],
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.errors = {};
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";

            this.image = this.item.image;
            this.fetchAlbums();
            this.imagePreview = [];
        });

    },
    methods: {
        fetchAlbums() {
            this.axios.get(process.env.VUE_APP_API_URL + this.url + '/albums').then(({ data }) => {
                this.albums = data.albums;
            });
        },
        clearImage(index, type) {
            if (type == "mounted") {
                this.image.splice(index, 1);
            }
            else if (type == "preview") {
                this.imagePreview.splice(index, 1);
            }
        },

        handleFileUpload(e) {

            this.filePreview = [];
            const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg", "webp"];

            for (let i = 0; i < e.target.files.length; i++) {

                if (imageExtensions.includes(e.target.files[i].type.split("/")[1])) {

                    this.imagePreview.push(e.target.files[i]);
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.filePreview.push(e.target.result);
                    };

                    reader.readAsDataURL(e.target.files[i]);
                }

            }
        },

        clearAll() {
            this.filePreview = [];
            this.image = [];
            this.imagePreview = [];
            this.$ref.image.value = "";
            this.$ref.uploadFile.value = "";
        },

        submitForm() {
            let uri = this.api_url + this.url + '/update';
            this.isLoading = true;

            let payload = new FormData();
            payload.append('id', this.item.id);
            payload.append('title', this.item.title);
            payload.append('caption', this.item.caption);
            payload.append('album_id', this.item.album_id);
            payload.append('order_by', this.item.order_by);
            payload.append('status', this.item.status);

            if (this.imagePreview.length > 0) {
                this.imagePreview.forEach(element => {
                    payload.append('imagePreview[]', element);
                });
                payload.append('flag', 'preview');
            }
            if (this.image.length > 0) {
                this.image.forEach(element => {
                    payload.append('image[]', element);
                });
                payload.append('flag', 'image');
            }

            if (this.id != '') {
                payload.id = this.id;
            }

            this.axios.post(uri, payload
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.$refs.btnClose.click();
                this.$root.$emit('FETCH_GALLERIES');
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    this.pushErrors(error.response.data.errors);
                    this.$toast.error("Please check the form again.", {
                        position: "bottom-right",
                    });
                }
            });
        }
    },
};
</script>

