<template>
  <flex-layout>
    <Details />
    <page-header heading="Home" action="Add" />
    <div class="pt-24 px-24">
      <div class="row">
        
        <div class="col-md-3" v-for="item in dashboard_data" :key="item.index">
          <div class="card-dashboard" :class="cardColor()">
            <p class="fw-semibold text-white">Total {{ item.name }}</p>
            <h4 class="mt-4 text-white">{{ item.count }}</h4>
          </div>
        </div>
        <!-- <div class="col-md-3">
          <div class="card-dashboard secondary">
            <p class="fw-semibold text-white">Total Requests</p>
            <h4 class="mt-4 text-white">1896</h4>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card-dashboard">
            <p class="fw-semibold text-white">Total Requests</p>
            <h4 class="mt-4 text-white">1896</h4>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card-dashboard secondary">
            <p class="fw-semibold text-white">Total Requests</p>
            <h4 class="mt-4 text-white">1896</h4>
          </div>
        </div> -->
      </div>
    </div>
  </flex-layout>
</template>

<script>
import FlexLayout from "../../components/Layout/FlexLayout.vue";
import Details from "../../components/Offcanvas/Details.vue";
import PageHeader from "../../components/Utility/PageHeader.vue";
export default {
  name: "MainDashboard",
  components: {
    PageHeader,
    FlexLayout,
    Details,
  },
  data() {
    return {
      dashboard_data : []
    };
  },
  methods: {

    cardColor() {
      let num = Math.floor(Math.random() * 4) + 1;
      if (num % 2 == 0) {
        return "card1";
      } else {
        return "card2";
      }
    },
   fetchDashboardData() {
    this.axios.get(process.env.VUE_APP_API_URL + 'dashboard-data').then(({ data }) => {
            this.dashboard_data = data;
        });
   }
  },
 mounted() {
    this.fetchDashboardData();
  }
};
</script>



<style scoped>
.card1 {
  background: rgb(53, 56, 107);
  background: linear-gradient(90deg, rgba(53, 56, 107, 1) 0%, rgba(164, 46, 42, 1) 33%);
}

.card2 {
  background: rgb(53, 56, 107);
  background: linear-gradient(315deg, rgba(53, 56, 107, 1) 17%, rgba(164, 46, 42, 1) 50%);
}
</style>
