<template>
    <flex-layout>
        <AlbumModal />
        <page-header heading="Album" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />
        <AlbumTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import AlbumTable from "@/components/Album/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import AlbumModal from "@/components/Album/modal.vue";
export default {
    name: "Album",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        AlbumTable,
        AlbumModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Thumbnail",
                },
                {
                    heading: "Image Count",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  