<template>
    <flex-layout styleClasses="p-24">
        <TableQuery :categories="categories" :params="params" :sorts="sorts" :resetFilters="resetFilters" :sortBy="sortBy" />

        <div class="position-relative h-100">
            <absolute-layout :scrollable="true" styleClass="table-responsive">
                <table class="table table-01" width="50%">
                    <thead>
                        <th v-for="item in tableHeading" :key="item.index">
                            {{ item.heading }}
                        </th>


                    </thead>
                    <Spinner v-if="isLoading" />
                    <TableData :tableData="tableData" v-else>
                        <tr v-for="item in tableData" :key="item.index">
                            <td>
                                {{ item.name }}
                                <span class="small">
                                    {{ item.email }}
                                </span>
                                <span class="small">
                                    {{ item.contact_number }}
                                </span>
                            </td>
                            <td>
                                {{ item.career }}[{{ item.valid_till }}]
                            </td>

                            <td>
                                {{ item.gpa }}
                            </td>

                            <!-- <td> -->
                            <!--     <a :href="storage + item.cv" target="_blank"> -->
                            <!--         <i class="ic-file"></i> -->
                            <!--         CV -->
                            <!--     </a> -->
                            <!--     | -->
                            <!--     <a :href="storage + item.cover_letter" target="_blank"> -->
                            <!--         <i class="ic-file"></i> -->
                            <!--         Cover Letter -->
                            <!--     </a> -->
                            <!-- </td> -->

                            <!-- <td> -->


                                <!---->
                                <!-- <div class="dropdown"> -->
                                <!--     <button class="btn btn-outline-warning dropdown-toggle btn-sm" type="button" -->
                                <!--         id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> -->
                                <!--         Documents -->
                                <!--     </button> -->
                                <!--     <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1"> -->
                                <!--         <li v-for="(data, index) in item.transcripts" :key="index"> -->
                                <!--             <a :href="storage + data.path" target="_blank"> -->
                                <!--                 {{ data.name }} -->
                                <!--             </a> -->
                                <!--         </li> -->
                                <!--     </ul> -->
                                <!-- </div> -->



                                <!-- <template v-for="(data, index) in item.transcripts">
                                    <a :href="storage + data.path" target="_blank" :key="index">
                                        {{ data.name }}
                                    </a>
                                </template> -->
                            <!-- </td> -->

                            <td>
                                {{ item.submitted_on }}
                            </td>

                            <td class="action" style="display: table-cell;">
                                <button title="Export" class="btn btn-sm btn-gray mr-12">
                                    <a :href="uri + '/export-individual/' + item.id">
                                        <i class="ic-report"></i>
                                    </a>
                                </button>
                                <button title="Delete" class="btn btn-sm btn-gray" @click="deleteData(item.id)"
                                    v-if="$can($route.meta.guard + '.delete')">
                                    <i class="ic-delete"></i>
                                </button>
                            </td>
                        </tr>
                    </TableData>
                </table>
                <div class="table-footer">
                    <PerPage :options="options" :params="params" />
                    <paginate :page-class="'page-item'" :page-count="pageCount" :page-link-class="'page-link'"
                        :container-class="'pagination  pagination-sm'" :prev-text="`<i class='ic-chevron-left'></i>`"
                        :next-text="` <i class='ic-chevron-right'></i>`" :prev-class="'page-link pagination-arrow mr-2'"
                        :next-class="'page-link pagination-arrow ml-2'" :click-handler="handlePagination" ref="paginate">
                    </paginate>
                </div>
            </absolute-layout>
        </div>

    </flex-layout>
</template>
  

<script>
import AbsoluteLayout from "@/components/Layout/AbsoluteLayout.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import TableMixin from "@/mixins/table.vue";
import Spinner from "@/components/Utility/Spinner.vue";
import PaginationMixin from "@/mixins/pagination.vue";
import PerPage from "@/components/Utility/PerPage.vue";
import TableQuery from "@/components/Utility/CareerApplicationTableQuery.vue";
import TableData from "@/components/Utility/TableData.vue";
import Permissions from "@/mixins/Permissions.vue";

export default {
    name: 'CareerApplicationTable',
    components: {
        AbsoluteLayout, FlexLayout, PerPage,
        Spinner, TableQuery, TableData
    },
    mixins: [TableMixin, PaginationMixin, Permissions],
    props : ['categories'],
    data() {
        return {
            url: 'careerapplications',
            fetch_module: 'FETCH_CAREER_APPLICATIONS',
            uri: process.env.VUE_APP_API_URL + "careerapplications",
        };
    },

};
</script>
<style scoped></style>
    
