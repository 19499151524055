import axios from "axios";
import { store } from "./store";

axios.interceptors.request.use((request) => {
  if (store.getters.isAuthenticated == true) {
    request.headers.common.Authorization = `Bearer ${store.getters.getToken}`;
  }
  return request;
});


// axios.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     if (error.response.status === 401) {
//       store.commit("LOG_OUT");
//     }
//   }
// );
