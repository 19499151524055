<template>
  <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
    aria-labelledby="offcanvasCreateLabel">
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">
        {{ mode }} Product/Service
      </h5>
      <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnClose">
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

          <div class="col-md-12 mb-16">
            <label for="">Name</label>
            <input type="text" class="form-control" :class="{ 'border-red': errors.name }" v-model="item.name"
              @input="createSlug" />
            <ErrorSpan :error="errors.name" />
          </div>


          <div class="col-md-12 mb-16">
            <label for="">Slug</label>
            <input type="text" class="form-control" v-model="item.slug" />
            <ErrorSpan :error="errors.slug" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Parent</label>
            <select name="parent_id" class="form-select" :class="{ 'border-red': errors.parent_id }"
              v-model="item.parent_id">
              <option value="" selected>Select Parent</option>
              <option v-for="(cat, key) in parents" :key="key" :value="cat.id"> {{ cat.name }} </option>
            </select>
            <ErrorSpan :error="errors.parent_id" />
          </div>

          <div class="col-md-12 mb-16">
            <label for="">Summary</label>
            <ckeditor v-model="item.summary" :config="editorConfig"></ckeditor>
            <ErrorSpan :error="errors.summary" />
          </div>

          <div class="row mb-16">
            <label for="">Image</label>
            <input type="file" class="form-control" ref="image" @input="handleImageUpload" />
            <div class="col-md-6 mt-16" v-if="upload.status == false">
              <UploadedImg :file='(storage + item.image)' :clearUploadedFile="clearUploadedImage"
                v-if="(mode === 'Edit' && item.image)" />
            </div>
            <div class="col-md-6 mt-16" v-else>
              <img :src="upload.imgPreview" alt="" height="100" width="100">
            </div>
            <ErrorSpan :error="errors.image" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Display Order</label>
            <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
              class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
            <ErrorSpan :error="errors.order_by" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Display Type</label>
            <select name="type" class="form-select" :class="{ 'border-red': errors.display_type }"
              v-model="item.display_type">
              <option value="" selected>Select Type</option>
              <option value="1">Accordion</option>
              <option value="2">Plain</option>
            </select>
            <ErrorSpan :error="errors.display_type" />
          </div>

          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" v-model="item.status" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
            </div>
          </div>
          <div class="col-md-12">
            <button type="button" aria-label="Close" @click="closeForm" class="btn btn-red mr-16">
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue";
import CKEditor from "ckeditor4-vue";
import ErrorSpan from "@/components/errorspan.vue";
import UploadedImg from "@/components/Utility/UploadedImg.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";


export default {
  name: "CreateMenu",
  components: {
    ckeditor: CKEditor.component,
    ErrorSpan, UploadedImg
  },
  mixins: [CreateMixin, UploadPreviewMixin, MediasMixin],
  props: ['parents'],

  data() {
    return {
      url: "product-service",
      mode: "",
      fetch_module: "FETCH_PRODUCTS",
      editorConfig: {
        allowedContent: true
      }
    };
  },
  created() {
    this.$root.$on("CREATE", () => {
      this.isEdit = false;
      this.item = {
        status: false,
      };
      this.errors = {};
      this.mode = "Add";
     this.clearselectedFiles();
    });

    this.$root.$on("EDIT", (data) => {
      this.errors = {};
      this.isEdit = true;
      let uri = process.env.VUE_APP_API_URL + "product-service/edit/" + data.id;
      this.axios
        .get(uri)
        .then((response) => {
          this.item = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.mode = "Edit";
     this.clearselectedFiles();
    });
  },
  methods: {
    closeForm() {
      this.$refs.btnClose.click();
    },
    createSlug() {
      this.item.slug = this.item.name.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-').replace(/^-+/, '').replace(/-+$/, '');
    },
  },
};
</script>
