<template>
    <flex-layout>
        <GalleryModal />
        <GalleryEditModal />
        <page-header heading="Gallery" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />
        <GalleryTable :tableHeading="tableHeading" />
    </flex-layout>
</template>

<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import GalleryTable from "@/components/Gallery/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import GalleryModal from "@/components/Gallery/modal.vue";
import GalleryEditModal from "@/components/Gallery/datamodal.vue";

export default {
    name: "Gallery",
    components: {
        PageHeader,
        FlexLayout,
        GalleryTable,
        GalleryModal,
        GalleryEditModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Caption",
                },
                {
                    heading: "Album",
                },
                {
                    heading: "Image Count",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
