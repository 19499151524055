<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="cashdatamodal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Right Share Eligibility Data</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Physical Holder Number</label>
                        <input type="text" class="form-control" v-model="item.physical_holder_no" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.physical_holder_no" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Promoter Share</label>
                        <input type="text" class="form-control" v-model="item.promoter_share" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.promoter_share" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Public Share</label>
                        <input type="text" class="form-control" v-model="item.public_share" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.public_share" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Total Kitta</label>
                        <input type="text" class="form-control" v-model="item.total_kitta" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.total_kitta" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Eligible For Right</label>
                        <input type="text" class="form-control" v-model="item.eligible_for_right" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.eligible_for_right" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Company</label>
                        <select name="company_id" class="form-select" :class="{ 'border-red': errors.company_id }"
                            v-model="item.company_id" :readonly="!editMode" :disabled="!editMode">
                            <option value="" disabled selected>Select Company</option>
                            <option v-for="(cat, key) in companies" :key="key" :value="cat.id">{{ cat.name }}</option>
                        </select>
                        <ErrorSpan :error="errors.company_id" />
                    </div>


                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";

export default {
    name: "RightShareEligibilityCheckModal",
    mixins: [CreateMixin, UploadPreviewMixin, MediasMixin],
    components: { ErrorSpan },
    props: ['companies'],
    data() {
        return {
            url: 'right-share-eligibility-check',
            mode: "",
            editMode: true,
            fetch_module: 'FETCH_RIGHT_SHARE_ELIGIBILITY_CHECK',
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
        });

    },
};
</script>

