<template>
    <flex-layout>
        <DRNRejectionCheckModal :companies="companies" />
        <DRNRejectionCheckDataModal :companies="companies" />
        <DRNDeleteModal/>
        <page-header heading="DRN Rejection Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>

                <div class="dropdown">
                    <button class="btn btn-outline-warning dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Template
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li> <vue-excel-xlsx class="btn btn-sm btn-outline-red col-xl-12" :data="delete_rdrn.data"
                                :columns="delete_rdrn.columns" file-name="drndeletetemplate" :file-type="'xlsx'"
                                sheet-name="DRNDeletion">
                                <i class="ic-delete" /> Delete
                            </vue-excel-xlsx></li>
                        <li>
                            <vue-excel-xlsx class="btn btn-sm btn-outline-green col-xl-12" :data="rejected_drn_list.data"
                                :columns="rejected_drn_list.columns" file-name="drnrejectioncheckchecktemplate"
                                :file-type="'xlsx'" sheet-name="DRNRejectionCheckTemplate">
                                <i class="ic-download" /> Upload
                            </vue-excel-xlsx>
                        </li>
                    </ul>
                </div>

                <button class="btn btn-sm btn-outline-red" data-bs-toggle="offcanvas" data-bs-target="#deletedrndatamodal"
                    aria-controls="offcanvasCreate">
                    Delete
                    <i class="ic-delete"></i>
                </button>


            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>

        </page-header>

        <DRNRejectionCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import DRNRejectionCheckTable from "@/components/DRNRejectionCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import DRNRejectionCheckModal from "@/components/DRNRejectionCheck/modal.vue";
import DRNRejectionCheckDataModal from "@/components/DRNRejectionCheck/datamodal.vue";
import DRNDeleteModal from "@/components/DRNRejectionCheck/deletemodal.vue";

export default {
    name: "DRNRejectionCheck",
    components: {
        PageHeader,
        FlexLayout,
        DRNRejectionCheckTable,
        DRNRejectionCheckModal,
        DRNRejectionCheckDataModal,
        DRNDeleteModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'drn-rejection-check',
            companies: {},
            tableHeading: [
                {
                    heading: "DRN",
                },
                {
                    heading: "Name",
                },
                {
                    heading: "Company",
                },
                {
                    heading: "Kitta",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Reason",
                },
            ],
            rejected_drn_list: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "drn",
                        field: "drn",
                    }, {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "kitta",
                        field: "kitta",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        drn: '111111',
                        name: 'FirstName LastName',
                        share_holder_no: '558893',
                        kitta: '10',
                        remarks: 'remarks'
                    },

                ],


            },

            delete_rdrn: {
                columns: [
                    {
                        label: 'company_id',
                        field: 'company_id'
                    },
                    {
                        label: "boid",
                        field: "boid",
                    },

                    {
                        label: "drn",
                        field: "drn",
                    }

                ],
                data: [
                    {
                        company_id: '1',
                        boid: '1111111111111111',
                        drn: '111111',
                    },

                ],
            }

        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'company/rejected-drn-check';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
