<template>
    <flex-layout>
        <page-header heading="Home Description" :guard="this.$route.meta.guard" />
        <div class="position-relative flex-grow-1">
            <absolute-layout :scrollable="true">
                <div class="col-md-12 p-48 ">

                    <h4>Introduction</h4>
                    <hr>

                    <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="col-md-12 mb-16">
                            <label for="">Title</label>
                            <input type="text" class="form-control" v-model="item.intro_title" />
                            <ErrorSpan :error="errors.intro_title" />
                        </div>

                        <div class="col-md-12 mb-16">
                            <label for="">Description</label>
                            <ckeditor v-model="item.intro_desc"></ckeditor>
                            <ErrorSpan :error="errors.intro_desc" />
                        </div>

                        <div class="col-md-12 mb-16">
                            <label for="">Image</label>
                            <input type="file" class="form-control" ref="intro_image"
                                @input="handleImageUpload('intro_image')" />
                            <ErrorSpan :error="errors.intro_image" />

                            <template v-if="typeof (item.intro_image) == 'string' && item.intro_image != ''">
                                <img :src="storage_url + item.intro_image" alt="" srcset=""
                                    style="width: 200px;height: 200px;margin: 2px;">
                            </template>




                        </div>


                        <div class="col-md-3 mb-16">
                            <label for="">Button Title</label>
                            <input type="text" class="form-control" v-model="item.intro_button_title" />
                            <ErrorSpan :error="errors.intro_button_title" />
                        </div>

                        <div class="col-md-3 mb-16">
                            <label for="">Button Link</label>
                            <input type="text" class="form-control" v-model="item.intro_button_link" />
                            <ErrorSpan :error="errors.intro_button_link" />
                        </div>

                        <div class="col-md-3 mb-16">
                            <label for="">Years</label>
                            <input type="text" class="form-control" v-model="item.intro_years" />
                            <ErrorSpan :error="errors.intro_years" />
                        </div>

                        <div class="col-md-3 mb-16">
                            <label for="">Offices</label>
                            <input type="text" class="form-control" v-model="item.intro_office_count" />
                            <ErrorSpan :error="errors.intro_office_count" />
                        </div>


                        <hr>

                        <h4>Why choose us?</h4>

                        <br>

                        <div class="col-md-12 mb-16">
                            <label for="">Title</label>
                            <input type="text" class="form-control" v-model="item.wc_title" />
                            <ErrorSpan :error="errors.wc_title" />
                        </div>

                        <div class="col-md-12 mb-16">
                            <label for="">Description</label>
                            <ckeditor v-model="item.wc_desc"></ckeditor>
                            <ErrorSpan :error="errors.wc_desc" />
                        </div>

                        <div class="col-md-12 mb-16">
                            <label for="">Image</label>
                            <input type="file" class="form-control" ref="wc_image" @input="handleImageUpload('wc_image')" />
                            <ErrorSpan :error="errors.wc_image" />
                            <template v-if="typeof (item.wc_image) == 'string' && item.wc_image != ''">
                                <img :src="storage_url + item.wc_image" alt="" srcset=""
                                    style="width: 200px;height: 200px;margin: 2px;">
                            </template>
                        </div>





                        <div class="col-md-6 mb-16">
                            <label for="">Button Title</label>
                            <input type="text" class="form-control" v-model="item.wc_button_title" />
                            <ErrorSpan :error="errors.wc_button_title" />
                        </div>

                        <div class="col-md-6 mb-16">
                            <label for="">Button Link</label>
                            <input type="text" class="form-control" v-model="item.wc_button_link" />
                            <ErrorSpan :error="errors.wc_button_link" />
                        </div>

                        <div class="col-md-12 mb-16">
                            <label for="">Pointers</label>
                            <div class="row p-4" v-for="(n, index) in item.wc_pointers" v-bind:key="index">
                                <div class="col-md-8">
                                    <input type="text" class="form-control" placeholder="Pointer"
                                        v-model="item.wc_pointers[index].pointer">
                                </div>
                                <div class="col-md-2 d-flex justify-content-evenly">
                                    <button class="btn btn-gray btn-large" type="button"
                                        @click="togglePointers('Add')">+</button>
                                    <button class="btn btn-gray btn-large" type="button"
                                        @click="togglePointers('Sub')">-</button>
                                </div>
                            </div>
                        </div>

                        <hr>
                        <div class="col-md-12">
                            <button v-if="!isLoading" class="btn btn-green float-end" type="submit" @click="submitForm">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green float-end" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </div>
                    </form>

                </div>
            </absolute-layout>
        </div>



    </flex-layout>
</template>

<script>
import AbsoluteLayout from "@/components/Layout/AbsoluteLayout.vue";
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CKEditor from 'ckeditor4-vue';
import PageHeader from "@/components/Utility/PageHeader.vue";
import ErrorSpan from "@/components/errorspan.vue";


export default {
    name: "HomeDescription",
    components: {
        AbsoluteLayout,
        FlexLayout,
        PageHeader,
        ErrorSpan,
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL + "home-description",
            storage_url: process.env.VUE_APP_API_STORAGE,
            isEdit: false,
            item: {
                id: '',
                intro_title: "",
                intro_desc: "",
                intro_button_title: "",
                intro_button_link: "",
                intro_years: "",
                intro_office_count: "",
                wc_title: "",
                wc_desc: "",
                wc_button_title: "",
                wc_button_link: "",
                wc_pointers: [{
                    pointer: ""
                }],
                wc_image: "",
                intro_image: "",

            },

            errors: {},
            isLoading: false,
        };

    },
    mounted() {
        this.fetchHomeDescription();
    },
    methods: {
        togglePointers(type) {
            if (type == 'Add') {
                this.item.wc_pointers.push({
                    pointer: ""
                });
            } else {
                if (this.item.wc_pointers.length > 1) {
                    this.item.wc_pointers.pop();
                }
            }
        },


        handleImageUpload(imgFile) {
            let file = this.$refs[imgFile].files[0];
            const reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                this.item[imgFile] = file;
            }
        },

        fetchHomeDescription() {
            this.axios.get(this.url).then(({ data }) => {
                let struct = { ...this.item };
                if (data.length > 0) {
                    this.isEdit = true;
                    this.item = data[0];
                }
                else {
                    this.item = struct;
                }
                this.isLoading = false;
            });
        },


        submitForm() {
            this.errors = {};
            this.isLoading = true;
            if (!this.isEdit) {
                delete this.item.id;
            }

            let formData = new FormData();

            for (let key in this.item) {
                if (key == 'wc_pointers') {
                    formData.append(key, JSON.stringify(this.item[key]));
                } else {
                    formData.append(key, this.item[key]);
                }
            }





            let uri = this.url + (this.isEdit ? '/update' : '/store');
            this.axios.post(uri, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.fetchOfficeInformation();
                window.location.reload();
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    if (error.response.status == 422) {
                        this.pushErrors(error.response.data.errors);
                        this.$toast.error("Please check the form again.", {
                            position: "bottom-right",
                        });
                    }
                }
            });
        },
        pushErrors(errors) {
            Object.entries(errors).forEach((element) => {
                let [key, msg] = element;
                this.errors[key] = msg[0];
            });
        }

    },

};
</script>
