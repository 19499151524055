<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="faileddematnotification" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Demat User</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.name" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Email</label>
                        <input type="text" class="form-control" v-model="item.email" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.email" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Error Message</label>
                        <textarea class="form-control" v-model="item.error_message" disabled></textarea>
                        <ErrorSpan :error="errors.error_message" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Failed At</label>
                        <input class="form-control" v-model="item.failed_at" :readonly="!editMode" :disabled="!editMode" />
                        <ErrorSpan :error="errors.failed_at" />
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";

export default {
    name: "FailedDematNotificationDataModal",
    mixins: [CreateMixin, MediasMixin],
    components: { ErrorSpan },
    data() {
        return {
            url: 'failed-demat-notification',
            fetch_module: 'FETCH_FAILED_DEMAT_NOTIFICATION',
            mode: "",
            editMode: true,
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
        });

    },
};
</script>

