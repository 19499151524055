<template>
    <div class="dropdown dropdown-01 notification">
        <a class="active" id="dropdownMenuButton1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="ic-notification"></i>
        </a>
        <ul class="dropdown-menu list-bullet" aria-labelledby="dropdownMenuButton1">
            <div class="flex-center-between">
                <p class="fw-semibold">Notification</p>
                <router-link to="/complaints" class="small text-primary">View all</router-link>
            </div>

            <template v-if="notifications.length > 0">
                <li v-for="(notify, index) in notifications" :key="index">
                    <router-link to="/complaints" class="">
                        <small class="clamp-3">
                            {{ notify.name }} has filed a complaint
                        </small>
                        <small class="text-cGray600 mt-2">{{ notify.time }}</small>
                    </router-link>
                </li>
            </template>

            <template v-else>
                <li><small class="clamp-3"> No New Complaints filled till now.</small></li>
            </template>


        </ul>
    </div>
</template>

<script>
import { eventbus } from "../../plugins/eventbus";

export default {
    name: 'ComplaintDropDown',
    data() {
        return {
            api_url: process.env.VUE_APP_API_URL,
            notifications: []

        }
    },
    methods: {
        fetchNotifications() {
            this.axios.get(this.api_url + 'complaint/notifications').then(({ data }) => {
                this.notifications = data.notifications;
            });
        }
    },
    mounted() {
        this.fetchNotifications();
        eventbus.$on('update-notifications', () => {
            this.fetchNotifications();
        });
    },
}

</script>