<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Opening</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Position</label>
                        <input type="text" class="form-control" v-model="item.position" @input="createSlug()" />
                        <ErrorSpan :error="errors.position" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Slug</label>
                        <input type="text" class="form-control" v-model="item.slug" />
                        <ErrorSpan :error="errors.slug" />
                    </div>


                    <div class="col-md-12 mb-16">
                        <label for="">Level</label>
                        <input type="text" min="1" class="form-control" v-model="item.level" />
                        <ErrorSpan :error="errors.level" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">No. of Openings</label>
                        <input type="number" min="1" class="form-control" v-model="item.no_of_openings" />
                        <ErrorSpan :error="errors.level" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Job Type (Full/Half Time)</label>
                        <input type="text" class="form-control" v-model="item.job_type" />
                        <ErrorSpan :error="errors.job_type" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Degree Types</label>
                        <div class="row p-4" v-for="(n, index) in item.degree_type" v-bind:key="index">
                            <div class="col-md-8">
                                <input type="text" class="form-control" placeholder="Enter Degree Type"
                                    v-model="item.degree_type[index].degree">
                            </div>
                            <div class="col-md-2 d-flex justify-content-evenly">
                                <button class="btn btn-gray btn-large mr-12" type="button"
                                    @click="toggleDegree('Add')">+</button>
                                <button class="btn btn-gray btn-large" type="button"
                                    @click="toggleDegree('Sub')">-</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">File Types</label>
                        <div class="row p-4" v-for="(n, index) in item.file_types" v-bind:key="index">
                            <div class="col-md-8">
                                <input type="text" class="form-control" placeholder="File Types"
                                    v-model="item.file_types[index].name">
                            </div>
                            <div class="col-md-2 d-flex justify-content-evenly">
                                <button class="btn btn-gray btn-large mr-12" type="button"
                                    @click="toggleFileTypes('Add','file')">+</button>
                                <button class="btn btn-gray btn-large" type="button"
                                    @click="toggleFileTypes('Sub','file')">-</button>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6 mb-16">
                        <label for="">Shift/Hours</label>
                        <input type="text" class="form-control" v-model="item.shift" />
                        <ErrorSpan :error="errors.shift" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Description</label>
                        <ckeditor v-model="item.description" :config="editorConfig"></ckeditor>
                        <ErrorSpan :error="errors.description" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Valid Till</label>
                        <VNepaliDatePicker classValue="form-control" calenderType="Nepali" placeholder="YYYY-MM-DD"
                            :value="valid_till_bs" :key="valid_till_bs" v-model="item.valid_till_bs" />
                        <span v-if="errors.date_bs" class="text-danger font-size-13">{{ errors.valid_till_bs }}</span>
                    </div>

                    <div class="col-md-6 mb-16">
                        <input disabled min="1" class="form-control" :value="item.valid_till_ad" />
                    </div>

                    <div class="col-md-16 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>
                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="button" @click="submitForm()">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import VNepaliDatePicker from "v-nepalidatepicker";
import CKEditor from 'ckeditor4-vue';
import { toAd } from "@/plugins/toAD";


export default {
    name: "CareerOpeningsModal",
    mixins: [CreateMixin],
    components: {
        ErrorSpan, VNepaliDatePicker,
        ckeditor: CKEditor.component
    },
    data() {
        return {
            url: process.env.VUE_APP_API_URL + "career-openings",
            storage_url: process.env.VUE_APP_API_STORAGE,
            mode: "",
            fetch_module: 'FETCH_CAREER_OPENINGS',
            editorConfig: {
                format_tags: 'p;h1;h2;h3;h4;h5;h6;pre;address;div'
            },
            item : {
                id : null,
                position : "",
                position_np : "",
                level : "",
                level_np : "",
                slug : "",
                job_type : "",
                job_type_np : "",
                shift : "",
                shift_np : "",
                no_of_openings : 0,
                no_of_openings_np : 0,
                description: "",
                description_np : "",
                valid_till_bs : "",
                status : "",
                file_types: [{
                    name: ""
                }],
                degree_type: [{
                    degree: "",
                }],
            }

        };
    },
    computed: {
        valid_till_bs() {
            return this.item.valid_till_bs != undefined ? this.item.valid_till_bs : '';
        }

    },
    mounted() {


        this.$root.$on('CREATE', () => {
            this.isEdit = false;
            this.item = {
                status: 0,
                file_types: [{
                    name: ""
                }],
                degree_type: [{
                    degree: "",
                }],
            };
            this.errors = {};
            this.mode = "Add";
        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.isEdit = true;
            this.item = { ...data };
            if( this.item.file_types == null || this.item.file_types.length < 0) {
              this.item.file_types = [{
              name : ""
              }]
            console.log("testing length", this.item.file_types);
              }
            if( this.item.degree_type == null || this.item.degree_type.length < 0) {
              this.item.degree_type = [{
              degree : ""
              }]  
            }
            this.mode = "Edit";
        });

    },
    methods: {
        toAd,
        createSlug(){
            this.item.slug = this.item.position.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
        },

        toggleDegree(type) {
            if (type == 'Add') {
                this.item.degree_type.push({
                    degree: ""
                });

            } else {
                if (this.item.degree_type.length > 1) {
                    this.item.degree_type.pop();
                }
            }
        },

        toggleFileTypes(type) {
            if (type == 'Add') {
                this.item.file_types.push({
                    name: ""
                });
            } else {
                if (this.item.file_types.length > 1) {
                    this.item.file_types.pop();
                }
            }
        },
        submitForm() {
            this.errors = {};
            this.isLoading = true;
            if (!this.isEdit) {
                delete this.item.id;
            }

            let formData = new FormData();

            for (let key in this.item) {
                console.log("item key", key)

                if (key == 'file_types' ) {
                    formData.append(key, JSON.stringify(this.item[key]));
                }
                else if (key == 'degree_type' ) {
                    formData.append('degree_type', JSON.stringify(this.item.degree_type));
                }
                 else {
                    console.log("item key", key)
                    formData.append(key, this.item[key]);
                }
            }

            let uri = this.url + (this.isEdit ? '/update' : '/store');
            this.axios.post(uri, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                window.location.reload();
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    if (error.response.status == 422) {
                        this.pushErrors(error.response.data.errors);
                        this.$toast.error("Please check the form again.", {
                            position: "bottom-right",
                        });
                    }
                }
            });
        },
        pushErrors(errors) {
            Object.entries(errors).forEach((element) => {
                let [key, msg] = element;
                this.errors[key] = msg[0];
            });
        }
    },

    watch: {
        'item.valid_till_bs'(new_bs_date, old_bs_date) {
            if (new_bs_date != '' && (new_bs_date != old_bs_date)) {
                this.item.valid_till_ad = this.toAd(new_bs_date)
            }
        }
    },
};
</script>

<style scoped>

</style>
