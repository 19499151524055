<template>
    <flex-layout>
        <DematUserModal />
        <DematUserDataModal />
        <page-header heading="Demat User" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>
                <vue-excel-xlsx class="btn btn-sm btn-outline-yellow" :data="demat_user.data" :columns="demat_user.columns"
                    file-name="DematUserTemplate" :file-type="'xlsx'" sheet-name="DematUserCheckTemplate">
                    <i class="ic-download" /> Template
                </vue-excel-xlsx>
            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>

            </template>

        </page-header>
        <DematUserTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import DematUserTable from "@/components/DematUser/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import DematUserModal from "@/components/DematUser/modal.vue";
import DematUserDataModal from "@/components/DematUser/datamodal.vue";

export default {
    name: "DematUserCheck",
    components: {
        PageHeader,
        FlexLayout,
        DematUserTable,
        DematUserModal,
        DematUserDataModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'demat-user',
            companies: {},
            tableHeading: [
                {
                    heading: "BOID",
                },
                {
                    heading: "Name"
                },
                {
                    heading: "Email"
                },

                {
                    heading: "Contact Number"
                },


                {
                    heading: "Created On"
                },
                {
                    heading: "Renewed On"
                },
                {
                    heading: "Expires On"
                },
            ],
            demat_user: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "name",
                        field: "name"
                    },
                    {
                        label: "email",
                        field: "email"
                    },
                    {
                        label: "contact_number",
                        field: "contact_number"
                    },
                    {
                        label: "created_on",
                        field: "created_on"
                    },
                    {
                        label: "renewed_on",
                        field: "renewed_on"
                    },
                    {
                        label: "expires_on",
                        field: "expires_on"
                    },


                ],
                data: [
                    {

                        boid: "1234567891111111",
                        name: "John Doe",
                        created_on: "2020-01-01",
                        renewed_on: "2020-03-01",
                        expires_on: "2020-09-01",
                        email: "test@email.com",
                        contact_number: "1234567890",

                    },

                ],
            },
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

    }
};
</script>
