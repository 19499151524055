import Vue from "vue";
import Router from "vue-router";
import VueAxios from "vue-axios";
import axios from "axios";
import Login from "../views/Login/Login.vue";
import Main from "../views/Dashboard/index.vue";
import MainDashboard from "../views/Dashboard/MainDashboard.vue";
import PopUp from "../views/PopUp/index.vue";
import Carousel from "../views/Carousel/index.vue";
import DownloadCategory from "../views/DownloadCategory/index.vue";
import Download from "../views/Download/index.vue";
import Menus from "../views/Menus/index.vue";
import News from "../views/News/index.vue";
import NoticeCategory from "../views/NoticeCategory/index.vue";
import Notice from "../views/Notice/index.vue";
import Member from "../views/Member/index.vue";
import FooterLink from "@/views/FooterLink/index.vue";
import OfficeInformation from "@/views/OfficeInformation/index.vue";
import UserManagement from "@/views/User/index.vue";
import RoleSetup from "@/views/RoleSetup/index.vue";
import UserRole from "@/views/UserRole/index.vue";
import Activity from "@/views/Activity/index.vue";
import FaqCategory from "@/views/FaqCategory/index.vue";
import Faq from "@/views/Faq/index.vue";
import CareerOpening from "@/views/CareerOpening/index.vue";
import CareerApplications from "@/views/CareerApplications/index.vue";
import MutualFundScheme from "@/views/MutualFund/Scheme/index.vue";
import MutualFundNav from "@/views/MutualFund/NAV/index.vue";
import Company from "@/views/Company/index.vue";
import ProductServices from "@/views/ProductServices/index.vue";
import ServiceShowList from "@/views/ServiceShowList/index.vue";
import UncollectedCashDividendCheck from "@/views/UncollectedCashDividendCheck/index.vue";
import PressRelease from "@/views/PressRelease/index.vue";
import DebentureInterestCheck from "@/views/DebentureInterestCheck/index.vue";
import MutualFundMenu from "@/views/MutualFund/Menu/index.vue";
import MFListingContent from "@/views/MutualFund/Listing/index.vue";
import MFDetailContent from "@/views/MutualFund/Detail/index.vue";
import Complaint from "@/views/Complaint/index.vue";
import CorporateProfile from "@/views/CorporateProfile/index.vue";
import HomeDescription from "@/views/HomeDescription/index.vue";
import InvestorRelationCategory from "@/views/InvestorRelationCategory/index.vue";
import MutualFundSchemeProspectus from "@/views/MutualFund/Prospectus/index.vue";
import ServiceFaq from "@/views/ServiceFaq/index.vue";
import InvestorRelation from "@/views/InvestorRelation/index.vue";
import DRNRejectionCheck from "@/views/DRNRejectionCheck/index.vue";
import RightShareEligibilityCheck from "@/views/RightShareEligibilityCheck/index.vue";
import NewsLetter from "@/views/NewsLetter/index.vue";
import ServiceLink from "@/views/ServiceLink/index.vue";
import DematUser from "@/views/DematUser/index.vue";
import FailedDematNotification from "@/views/FailedDematNotification/index.vue";
import ExpiredDematUser from "@/views/ExpiredDematUser/index.vue";
import Album from "@/views/Album/index.vue";
import Gallery from "@/views/Gallery/index.vue";
import AuctionShare from "@/views/AuctionShare/index.vue";
import IpoResult from "@/views/IpoResult/index.vue";
import RegistarToShare from "@/views/RegistarToShare/index.vue";

import { store } from "../store.js";

Vue.use(Router);
Vue.use(VueAxios, axios);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthorized = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (to.meta.guard == "home") {
      next();
    } else if (
      store.getters.getPermissions.indexOf(to.meta.guard + "." + "view") !== -1
    ) {
      next();
    } else {
      next({ path: "", replace: true });
    }
  } else {
    return next("/login");
  }
};

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      component: Main,
      children: [
        {
          path: "",
          name: "MainDashboard",
          component: MainDashboard,
          beforeEnter: ifAuthorized,
          meta: { guard: "home" },
        },
        {
          path: "popup",
          name: "PopUp",
          component: PopUp,
          meta: { guard: "popup" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "carousel",
          name: "Carousel",
          component: Carousel,
          meta: { guard: "carousel" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "download-category",
          name: "DownloadCategory",
          component: DownloadCategory,
          meta: { guard: "downloadcategory" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "download",
          name: "Download",
          component: Download,
          meta: { guard: "download" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "menus",
          name: "Menus",
          component: Menus,
          meta: { guard: "menu" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "news",
          name: "News",
          component: News,
          meta: { guard: "news" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "notice-category",
          name: "NoticeCategory",
          component: NoticeCategory,
          meta: { guard: "noticecategory" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "notice",
          name: "Notice",
          component: Notice,
          meta: { guard: "notice" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "member",
          name: "Member",
          component: Member,
          meta: { guard: "member" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "/footer-links",
          name: "FooterLink",
          component: FooterLink,
          meta: { guard: "footerlink" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "/office-information",
          name: "OfficeInformation",
          component: OfficeInformation,
          meta: { guard: "officeinformation" },
          beforeEnter: ifAuthorized,
        },
        {
          path: "/users",
          name: "Users",
          component: UserManagement,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/role-setup",
          name: "RoleSetup",
          component: RoleSetup,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/user-role",
          name: "UserRole",
          component: UserRole,
          beforeEnter: ifAuthorized,
          meta: { guard: "user" },
        },
        {
          path: "/activity",
          name: "Activity",
          component: Activity,
          beforeEnter: ifAuthorized,
          meta: { guard: "activity" },
        },
        {
          path: "/faq-category",
          name: "FaqCategory",
          component: FaqCategory,
          beforeEnter: ifAuthorized,
          meta: { guard: "faqcategory" },
        },
        {
          path: "/faq",
          name: "Faq",
          component: Faq,
          beforeEnter: ifAuthorized,
          meta: { guard: "faq" },
        },
        {
          path: "/career-opening",
          name: "CareerOpening",
          component: CareerOpening,
          beforeEnter: ifAuthorized,
          meta: { guard: "careeropenings" },
        },
        {
          path: "/applications",
          name: "Applications",
          component: CareerApplications,
          beforeEnter: ifAuthorized,
          meta: { guard: "careerapplication" },
        },
        {
          path: "/mutual-fund-scheme",
          name: "MutualFundScheme",
          component: MutualFundScheme,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundscheme" },
        },
        {
          path: "/mutual-fund-nav",
          name: "MutualFundNAV",
          component: MutualFundNav,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundnav" },
        },
        {
          path: "/company",
          name: "Company",
          component: Company,
          beforeEnter: ifAuthorized,
          meta: { guard: "company" },
        },
        {
          path: "/product-services",
          name: "ProductServices",
          component: ProductServices,
          beforeEnter: ifAuthorized,
          meta: { guard: "productservice" },
        },
        {
          path: "/service-showlist",
          name: "ServiceShowlist",
          component: ServiceShowList,
          beforeEnter: ifAuthorized,
          meta: { guard: "serviceshowlist" },
        },

        {
          path: "/uncollected-cash-dividend-check",
          name: "UncollectedCashDividendCheck",
          component: UncollectedCashDividendCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "uncollectedcashdividend" },
        },
        {
          path: "/debenture-interest-check",
          name: "DebentureInterestCheck",
          component: DebentureInterestCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "debentureinterestcheck" },
        },
        {
          path: "/drn-rejection-check",
          name: "DRNRejectionCheck",
          component: DRNRejectionCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "drnrejection" },
        },
        {
          path: "/right-share-eligibility-check",
          name: "RightShareEligibilityCheck",
          component: RightShareEligibilityCheck,
          beforeEnter: ifAuthorized,
          meta: { guard: "rightshareeligibilitycheck" },
        },
        {
          path: "/press-release",
          name: "PressRelease",
          component: PressRelease,
          beforeEnter: ifAuthorized,
          meta: { guard: "pressrelease" },
        },
        {
          path: "/mutual-fund-menu",
          name: "MutualFundMenu",
          component: MutualFundMenu,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundmenu" },
        },
        {
          path: "/mf-listing-content",
          name: "MFListingContent",
          component: MFListingContent,
          beforeEnter: ifAuthorized,
          meta: { guard: "mflistingcontent" },
        },

        {
          path: "/mf-detail-content",
          name: "MFDetailContent",
          component: MFDetailContent,
          beforeEnter: ifAuthorized,
          meta: { guard: "mfdetailcontent" },
        },
        {
          path: "/complaints",
          name: "Complaints",
          component: Complaint,
          beforeEnter: ifAuthorized,
          meta: { guard: "complaint" },
        },
        {
          path: "/corporate-profile",
          name: "CorporateProfile",
          component: CorporateProfile,
          beforeEnter: ifAuthorized,
          meta: { guard: "corporateprofile" },
        },
        {
          path: "/home-description",
          name: "HomeDescription",
          component: HomeDescription,
          beforeEnter: ifAuthorized,
          meta: { guard: "homedescription" },
        },
        {
          path: "/investor-relation-category",
          name: "InvestorRelationCategory",
          component: InvestorRelationCategory,
          beforeEnter: ifAuthorized,
          meta: { guard: "investorrelationcategory" },
        },
        {
          path: "/investor-relation",
          name: "InvestorRelation",
          component: InvestorRelation,
          beforeEnter: ifAuthorized,
          meta: { guard: "investorrelation" },
        },
        {
          path: "/mutual-fund-scheme-prospectus",
          name: "MutualFundSchemeProspectus",
          component: MutualFundSchemeProspectus,
          beforeEnter: ifAuthorized,
          meta: { guard: "mutualfundschemeprospectus" },
        },
        {
          path: "/service-faq",
          name: "ServiceFaq",
          component: ServiceFaq,
          beforeEnter: ifAuthorized,
          meta: { guard: "servicefaq" },
        },
        {
          path: "/news-letter",
          name: "NewsLetter",
          component: NewsLetter,
          beforeEnter: ifAuthorized,
          meta: { guard: "newsletter" },
        },
        // dematuser
        {
          path: "/demat-user",
          name: "DematUser",
          component: DematUser,
          beforeEnter: ifAuthorized,
          meta: { guard: "dematuser" },
        },

        {
          path: "/expired-demat-user",
          name: "ExpiredDematUser",
          component: ExpiredDematUser,
          beforeEnter: ifAuthorized,
          meta: { guard: "dematuser" },
        },

        {
          path: "/service-link",
          name: "ServiceLink",
          component: ServiceLink,
          beforeEnter: ifAuthorized,
          meta: { guard: "servicelink" },
        },
        {
          path: "/failed-demat-notification",
          name: "FailedDematNotification",
          component: FailedDematNotification,
          beforeEnter: ifAuthorized,
          meta: { guard: "faileddematnotification" },
        },
        {
          path: "/album",
          name: "Album",
          component: Album,
          beforeEnter: ifAuthorized,
          meta: { guard: "album" },
        },
        {
          path: "/gallery",
          name: "Gallery",
          component: Gallery,
          beforeEnter: ifAuthorized,
          meta: { guard: "gallery" },
        },
        {
          path: "/auction-share-check",
          name: "AuctionShareCheck",
          component: AuctionShare,
          beforeEnter: ifAuthorized,
          meta: { guard: "auctionshare" },
        },
        {
          path: "/ipo-result-check",
          name: "Ipo Result Check",
          component: IpoResult,
          beforeEnter: ifAuthorized,
          meta: { guard: "iporesultcheck" },
        },
        {
          path: "/registar-to-share",
          name: "Registar To Share",
          component: RegistarToShare,
          beforeEnter: ifAuthorized,
          meta: { guard: "registartoshare" },
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated,
    },
    { path: "*", redirect: "/" },
  ],
});

export default router;
