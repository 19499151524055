<template>
    <flex-layout>
        <DebentureInterestCheckModal :companies="companies" />
        <DebentureInterestCheckDataModal :companies="companies" />
        <DBDeleteModal />
        <page-header heading="Debenture Interest Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>

                <div class="dropdown">
                    <button class="btn btn-outline-warning dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Template
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li> <vue-excel-xlsx class="btn btn-sm btn-outline-red col-xl-12" :data="delete_dbic.data"
                                :columns="delete_dbic.columns" file-name="deletedebentureinterestchecktemplate"
                                :file-type="'xlsx'" sheet-name="DebentureInterestCheckDeletion">
                                <i class="ic-delete" /> Delete
                            </vue-excel-xlsx></li>

                        <li>
                            <vue-excel-xlsx class="btn btn-sm btn-outline-green col-xl-12"
                                :data="debenture_interest_check.data" :columns="debenture_interest_check.columns"
                                file-name="debentureinterestchecktemplate" :file-type="'xlsx'"
                                sheet-name="DebentureInterestCheckTemplate">
                                <i class="ic-download" /> Upload
                            </vue-excel-xlsx>

                        </li>

                    </ul>
                </div>

                <button class="btn btn-sm btn-outline-red" data-bs-toggle="offcanvas" data-bs-target="#deletedbidatamodal"
                    aria-controls="offcanvasCreate">
                    Delete
                    <i class="ic-delete"></i>
                </button>

            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>

        </page-header>

        <DebentureInterestCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import DebentureInterestCheckTable from "@/components/DebentureInterestCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import DebentureInterestCheckModal from "@/components/DebentureInterestCheck/modal.vue";
import DebentureInterestCheckDataModal from "@/components/DebentureInterestCheck/datamodal.vue";
import DBDeleteModal from "@/components/DebentureInterestCheck/deletemodal.vue";

export default {
    name: "DebentureInterestCheck",
    components: {
        PageHeader,
        FlexLayout,
        DebentureInterestCheckTable,
        DebentureInterestCheckModal,
        DebentureInterestCheckDataModal,
        DBDeleteModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'debenture-interest-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Company",
                },

                {
                    heading: "BOID",
                },
                {
                    heading: "Debenture Holder Name",
                },
                {
                    heading: "Interest Amount",
                },
                {
                    heading: "Total Days",
                },
                {
                    heading: "Net Interest Amount",
                },
                {
                    heading: "Semi Year",
                },
                {
                    heading: "Fiscal Year",
                },
                {
                    heading: "Remarks",
                },
                {
                    heading: "File",
                }
            ],

            debenture_interest_check: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "debenture_holder_name",
                        field: "debenture_holder_name",
                    },
                    {
                        label: "interest_amt",
                        field: "interest_amt",
                    },
                    {
                        label: "total_days",
                        field: "total_days",
                    },
                    {
                        label: "net_interest_amt",
                        field: "net_interest_amt",
                    },
                    {
                        label: "semi_quarter",
                        field: "semi_quarter",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    },
                    {
                        label: "file",
                        field: "file",
                    },

                ],
                data: [
                    {
                        boid: '1111111111111111',
                        interest_amt: '111111',
                        debenture_holder_name: 'FirstName LastName',
                        remarks: '558893',
                        file: 'file',
                        semi_quarter: '255',
                        net_interest_amt: '20.3',
                        total_days: '10',
                    },

                ],


            },

            delete_dbic: {
                columns: [
                    {
                        label: "company_id",
                        field: "company_id",
                    },
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "fiscal_year",
                        field: "fiscal_year",
                    },
                    {
                        label: "semi_quarter",
                        field: "semi_quarter",
                    },
                ],
                data: [
                    {
                        company_id: '1111111111111111',
                        boid: '1111111111111111',
                        fiscal_year: '111111',
                        semi_quarter: '111111',
                    },
                ]

            }
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'company/debenture-interest-check';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
