<template>
    <flex-layout>
        <MFNAVModal :schemes="schemes"/>
        <page-header heading="Mutual Fund NAV" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />
        <MFNAVTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import MFNAVTable from "@/components/MutualFund/NAV/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import MFNAVModal from "@/components/MutualFund/NAV/modal.vue";
export default {
    name: "MutualFundNAV",
    components: {
        PageHeader,
        FlexLayout,
        MFNAVTable,
        MFNAVModal
    },
    data() {
        return {
            schemes:{},
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Scheme",
                },
                {
                    heading: "NAV",
                },
                {
                    heading: "Type",
                },
                {
                    heading: "File",
                },

                {
                    heading: "Status",
                },
            ],
        };
    },
    async created() {
        this.fetchSchemes();
    },

    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
        async fetchSchemes() {
            let url = process.env.VUE_APP_API_URL + 'mutual-fund-scheme/getSchemes';
            let {data} = await this.axios.get(url);
            this.schemes = data;
        }
    }
};
</script>
  