<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="deletedrndatamodal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">Delete DRN Rejection Data</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">Upload file</label>
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload"
                                accept=".xls,.xlsx" />
                            <ErrorSpan :error="errors.file" />
                        </div>
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";

export default {
    name: "DRNDeleteModal",
    mixins: [CreateMixin],
    components: { ErrorSpan },
    data() {
        return {
            url: 'drn-rejection-check/bulk-delete',
            mode: "",
            editMode: true,
            fetch_module: 'FETCH_DEBENTUREs',
        };
    },

    mounted() {
        this.clearSelectedFile();
    },
    methods: {
        handleFileUpload(e) {
            const [file] = e.target.files;
            this.item.file = file;
        },
        clearSelectedFile() {
            if (this.$refs.file) {
                this.$refs.file.value = "";
            }
        }
        ,
        submitForm() {
            let uri = this.api_url + this.url;
            this.isLoading = true;
            this.axios.post(uri, this.item, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.$refs.btnClose.click();
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    if (error.response.status == 422) {
                        this.pushErrors(error.response.data.errors);
                        this.$toast.error("Please check the form again.", {
                            position: "bottom-right",
                        });
                    }
                }
            });
        },
    },
};
</script>

