<template>
    <flex-layout>
        <page-header heading="Failed Demat Notification">
            <template v-slot:export>
                <button class="btn btn-sm btn-outline-danger" @click="retryAll()">Retry All</button>
            </template>
        </page-header>
        <FailedDNTable :tableHeading="tableHeading" />
        <FailedDematNotificationDataModal />
    </flex-layout>
</template>
  
<script>

import FlexLayout from "@/components/Layout/FlexLayout.vue";
import FailedDNTable from "@/components/FailedDematNotification/table.vue";
import FailedDematNotificationDataModal from "@/components/FailedDematNotification/datamodal.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";

export default {
    name: "FailedDematNotification",
    components: {
        PageHeader,
        FlexLayout,
        FailedDNTable,
        FailedDematNotificationDataModal
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'failed-demat-notification/',
            fetch_module: 'FETCH_FAILED_DEMAT_NOTIFICATION',
            tableHeading: [
                {
                    heading: "BOID",
                },
                {
                    heading: "Name"
                },
                {
                    heading: "Email"
                },

                {
                    heading: "Error"
                },
                {
                    heading: "Failed At"
                }
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
        retryAll() {
            this.axios.post(this.uri + 'retryAll').then((response) => {
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });

                this.$root.$emit(this.fetch_module);

            });
        }
    }
};
</script>
