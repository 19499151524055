<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="cashdatamodal" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Demat User</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">

                    <div class="col-md-12 mb-16">
                        <label for="">BOID</label>
                        <input type="text" class="form-control" v-model="item.boid" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.boid" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.name" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Email</label>
                        <input type="text" class="form-control" v-model="item.email" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.email" />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Contact Number</label>
                        <input type="text" class="form-control" v-model="item.contact_number" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.contact_number" />
                    </div>


                    <div class="col-md-4 mb-16">
                        <label for="">Created On</label>
                        <input type="date" class="form-control" v-model="item.created_on" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.created_on" />
                    </div>

                    <div class="col-md-4 mb-16">
                        <label for="">Renewed On</label>
                        <input type="date" class="form-control" v-model="item.renewed_on" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.renewed_on" />
                    </div>

                    <div class="col-md-4 mb-16">
                        <label for="">Expires On</label>
                        <input type="date" class="form-control" v-model="item.expires_on" :readonly="!editMode"
                            :disabled="!editMode" />
                        <ErrorSpan :error="errors.expires_on" />
                    </div>

                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="editMode">
                            <button v-if="!isLoading" class="btn btn-green" type="submit">
                                <i class="ic-checkmark mr-8"></i>Save
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import ErrorSpan from "@/components/errorspan.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";

export default {
    name: "DematUserDataModal",
    mixins: [CreateMixin, MediasMixin],
    components: { ErrorSpan },
    data() {
        return {
            url: 'demat-user',
            fetch_module: 'FETCH_DEMAT_USER',
            mode: "",
            editMode: true,
        };
    },
    computed: {

    },
    mounted() {

        this.$root.$on('EDIT', (data, editMode) => {
            this.editMode = editMode;
            this.isEdit = true;
            this.item = { ...data };
            this.editMode == true ? this.mode = "Edit" : this.mode = "View";
        });

    },
};
</script>

