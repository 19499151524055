<template>
    <flex-layout>
        <CorporateProfileModal/>
        <page-header heading="Corporate Profile" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <CorporateProfileTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import CorporateProfileTable from "@/components/CorporateProfile/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import CorporateProfileModal from "@/components/CorporateProfile/modal.vue";
export default {
    name: "Menus",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        CorporateProfileTable,
        CorporateProfileModal
        
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Image",
                },
                {
                    heading: "Status",
                },
                {
                    heading: "Order",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    },
    
};
</script>
  