<template>
    <flex-layout>
        <RegistarToShareModal />
        <page-header heading="Registar To Share" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />
        <RegistarToShareTable :tableHeading="tableHeading" />
    </flex-layout>
</template>

<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import RegistarToShareTable from "@/components/RegistarToShare/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import RegistarToShareModal from "@/components/RegistarToShare/modal.vue";
export default {
    name: "RegistarToShare",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        RegistarToShareTable,
        RegistarToShareModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Image",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>