<template>
  <flex-layout>
    <MFMenuModal :schemes ="schemes"/>
    <page-header heading="Mutual Fund Menu" action="Add" toggle="offcanvas" target="#offcanvasCreate"
      :guard="this.$route.meta.guard" @click="setCreateForm" />

    <MFMenuTable :tableHeading="tableHeading" />
  </flex-layout>
</template>

<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import MFMenuTable from "@/components/MutualFund/Menu/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import MFMenuModal from "@/components/MutualFund/Menu/modal.vue";
export default {
  name: "Menus",
  components: {
    // AbsoluteLayout,
    PageHeader,
    FlexLayout,
    MFMenuTable,
    MFMenuModal,
  },
  data() {
    return {
      schemes: [],
      tableHeading: [
        {
          heading: "Scheme",
        },
        {
          heading: "Title",
        },
        {
          heading: "Page Type",
        },
        {
          heading: "Status",
        },
      ],
    };
  },

  async created() {
    let url = process.env.VUE_APP_API_URL + 'mutual-fund-scheme/getSchemes';
    let { data } = await this.axios.get(url);
    this.schemes = data;
  },


  methods: {
    setCreateForm() {
      this.$root.$emit("CREATE");
    },


  },
};
</script>
