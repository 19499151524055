export const translateRole = (role) => {
  var roles = {
    activity: "Activity",
    careeropenings: "Career Openings",
    carousel: "Carousel",
    download: "Download",
    downloadcategory: "Download Category",
    faq: "FAQ",
    faqcategory: "FAQ Category",
    footerlink: "Footer Link",
    member: "Member",
    menu: "Menu",
    news: "News",
    notice: "Notice",
    noticecategory: "Notice Category",
    officeinformation: "Office Information",
    popup: "Popup",
    user: "User",
    careerapplication: "Career Application",
    company: "Company",
    complaint: "Complaint",
    corporateprofile: "Corporate Profile",
    drnrejection: "DRN Rejection Check",
    debentureinterestcheck: "Debenture Interest Check",
    investorrelations: "Investor Relations",
    homedescription: "Home Description",
    investorrelationcategory: "Investor Relation Category",
    mfdetailcontent: "Mutual Fund Detail Content",
    mflistingcontent: "Mutual Fund Listing Content",
    mutualfundmenu: "Mutual Fund Menu",
    mutualfundnav: "Mutual Fund NAV",
    mutualfundscheme: "Mutual Fund Scheme",
    mutualfundschemeprospectus: "Scheme Prospectus",
    pressrelease: "Press Release",
    productservice: "Product & Services",
    servicefaq: "Service FAQ",
    serviceshowlist: "Service Showcase",
    uncollectedcashdividend: "Uncollected Cash Dividend Check",
    visitor: "Visitor",
    rightshareeligibilitycheck: "RightShare Eligibility Check",
    newsletter: "Newsletter",
    servicelink: "Service Link",
    dematuser: "Demat User",
  };

  return roles[role] ?? role;
};
