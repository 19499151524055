<template>
  <div class="sidebar-01" :class="{ toggled: isToggled }">
    <div class="position-relative h-100">
      <absolute-layout :scrollable="true">
        <ul class="sidebar-01-list">
          <template v-for="data in menuList">
            <template v-if="data.hasSub && menuGuard(data, data.hasSub)">
              <li :key="data.index" :class="{ hasSub: data.hasSub }">
                <a type="button" data-bs-toggle="collapse" :data-bs-target="'#multiCollapseExample2_' + data.name"
                  aria-expanded="false" :aria-controls="'multiCollapseExample2_' + data.name">
                  {{ data.title }}
                </a>
                <div class="collapse multi-collapse" :id="'multiCollapseExample2_' + data.name">
                  <div class="card">
                    <template v-for="item in data.subMenu">
                      <router-link :to="item.link" v-if="menuGuard(item)" :key="item.index">
                        {{ item.innerTitle }}
                      </router-link>
                    </template>
                  </div>
                </div>
              </li>
            </template>
            <template v-else-if="menuGuard(data)">
              <li :key="data.index" :class="{ hasSub: data.hasSub }">
                <router-link :to="data.link"> {{ data.title }} </router-link>
              </li>
            </template>
          </template>
        </ul>
      </absolute-layout>
    </div>
  </div>
</template>

<script>
// import FlexLayout from "../Layout/FlexLayout.vue";
import AbsoluteLayout from "../Layout/AbsoluteLayout.vue";
export default {
  name: "AppSidebar",
  components: { AbsoluteLayout },
  props: { isToggled: Boolean },
  data() {
    return {
      menuList: [
        {
          title: "Home",
          link: "/",
          guard: "home",
          hasSub: false,
        },

        {
          title: "Home Description",
          link: "/home-description",
          guard: "homedescription",
          hasSub: false,
        },

        {
          title: "Carousel & PopUp",
          name: 'CarouselPopUp',
          hasSub: true,
          subMenu: [
            {
              innerTitle: "PopUp",
              guard: "popup",
              link: "/popup",
            },
            {
              innerTitle: "Carousel",
              guard: "carousel",
              link: "/carousel",
            },
          ],
        },

        {
          title: "About Us",
          name: 'AboutUs',
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Corporate Profile",
              link: "/corporate-profile",
              guard: "corporateprofile",
            },
            {
              innerTitle: "Member",
              guard: "member",
              link: "/member",
            }, 
            {
              innerTitle: "Album",
              link: "/album",
              guard: "album",
            },
            {
              innerTitle: "Gallery",
              link: "/gallery",
              guard: "gallery",
            }
          ],
        },
        {
          title: "Downloads",
          name: 'Downloads',
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Categories",
              link: "/download-category",
              guard: "downloadcategory",
            },
            {
              innerTitle: "Download",
              link: "/download",
              guard: "download",
            },
          ],
        },
        {
          title: "Menus",
          link: "/menus",
          hasSub: false,
          guard: "menu",
        },
        {
          title: "Media",
          name: 'Media',
          hasSub: true,
          subMenu: [
            {
              innerTitle: "News",
              link: "/news",
              guard: "news",
            },
            {
              innerTitle: "Notice Category",
              link: "/notice-category",
              guard: "noticecategory",
            },
            {
              innerTitle: "Notice",
              link: "/notice",
              guard: "notice",
            },

            {
              innerTitle: "Press Release",
              link: "/press-release",
              guard: "pressrelease",
            },
          ],
        },
        {
          title: "Investor Relation",
          name: 'InvestorRelation',
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Investor Relation Categories",
              link: "/investor-relation-category",
              guard: "investorrelationcategory",
            },
            {
              innerTitle: "Investor Relation",
              link: "/investor-relation",
              guard: "investorrelation",
            },
          ],
        },
        {
          title: "Products/Services",
          name: "product_service",
          hasSub: true,
          subMenu: [

            {
              innerTitle: "Product-Services",
              link: "/product-services",
              guard: "productservice",
            },

            {
              innerTitle: "Services ShowCase",
              link: "/service-showlist",
              guard: "serviceshowlist",
            },
            {
              innerTitle: "Service FAQ",
              link: "/service-faq",
              guard: "servicefaq",
            },
            {
              innerTitle: "Service Link",
              link: "/service-link",
              guard: "servicelink",
            },
            {
              innerTitle: "Registar To Share",
              link: "/registar-to-share",
              guard: "registartoshare",
            }


          ],
        },
        {
          title: "Checkpoints",
          name: "results",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Company",
              link: "/company",
              guard: "company",
            },
            {
              innerTitle: "Debenture Interest Check",
              link: "/debenture-interest-check",
              guard: "debentureinterestcheck",
            },
            {
              innerTitle: "Uncollected Cash Dividend Check",
              link: "/uncollected-cash-dividend-check",
              guard: "uncollectedcashdividend",
            },
            {
              innerTitle: "DRN Rejection Check",
              link: "/drn-rejection-check",
              guard: "drnrejection",
            },
            {
              innerTitle: "Right Share Eligibility Check",
              link: "/right-share-eligibility-check",
              guard: "rightshareeligibilitycheck",
            },
            {
              innerTitle: "Auction Share Check",
              link: "/auction-share-check",
              guard: "auctionshare",
            },
            {
              innerTitle: "IPO Result Check",
              link: "/ipo-result-check",
              guard: "iporesultcheck",
            },
          ],
        },
        {
          title: "Career/ Vacancy",
          name: "career",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Openings",
              link: "/career-opening",
              guard: "careeropenings",
            },
            {
              innerTitle: "Applications",
              link: "/applications",
              guard: "careerapplication",
            },
          ],
        },
        {
          title: "Mutual Fund",
          name: "mutual_fund",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Scheme",
              link: "/mutual-fund-scheme",
              guard: "mutualfundscheme",
            },
            {
              innerTitle: "NAV",
              link: "/mutual-fund-nav",
              guard: "mutualfundnav",
            },
            {
              innerTitle: "Menus",
              link: "/mutual-fund-menu",
              guard: "mutualfundmenu",
            },
            //mutualfundschemeprospectus
            {
              innerTitle: "Scheme Prospectus",
              link: "/mutual-fund-scheme-prospectus",
              guard: "mutualfundschemeprospectus",
            }

          ],
        },
        {
          title: "MF Menu Contents",
          name: "mf-mutual-contents",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Detail Content",
              link: "/mf-detail-content",
              guard: "mfdetailcontent",
            },
            {
              innerTitle: "Listing Content",
              link: "/mf-listing-content",
              guard: "mflistingcontent",
            },
          ],
        },

        {
          title: "Role Management",
          name: "role_management",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Role Setup",
              link: "/role-setup",
              guard: "user",
            },
            {
              innerTitle: "User Role",
              link: "/user-role",
              guard: "user",
            },
          ],
        },
        {
          title: "Users",
          link: "/users",
          hasSub: false,
          guard: "user",
        },
        {
          title: "Activity Log",
          link: "/activity",
          hasSub: false,
          guard: "activity",
        },
        {
          title: "Complaints",
          link: "/complaints",
          hasSub: false,
          guard: "complaint",
        },
        {
          title: "News Letter",
          link: "/news-letter",
          hasSub: false,
          guard: "newsletter",
        },

        {
          title: "Demat",
          name: "Demat",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Users",
              link: "/demat-user",
              guard: "dematuser",
            },

            {
              innerTitle: "Expired Users",
              link: "/expired-demat-user",
              guard: "dematuser",
            },

            {
              innerTitle: "Failed Notifications",
              link: "/failed-demat-notification",
              guard: "faileddematnotification",
            },
          ],
        },

        {
          title: "FAQs",
          name: "FAQ",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "FAQ Category",
              link: "/faq-category",
              guard: "faqcategory",
            },
            {
              innerTitle: "FAQ",
              link: "/faq",
              guard: "faq",
            },
          ],
        },
        {
          title: "Footer",
          name: "Footer",
          hasSub: true,
          subMenu: [
            {
              innerTitle: "Footer Link",
              link: "/footer-links",
              guard: "footerlink",
            },

            {
              innerTitle: "Office Information",
              link: "/office-information",
              guard: "officeinformation",
            },
          ],
        },
      ],
    };
  },
  methods: {
    menuGuard(menu, hasSub = false) {
      if (menu.guard == "home") {
        return true;
      }
      if (hasSub == true) {
        let active = false;
        menu.subMenu.forEach((el) => {
          if (
            this.$store.getters.getPermissions.indexOf(
              el.guard + "." + "view"
            ) !== -1
          ) {
            active = true;
          }
        });
        return active;
      } else {
        return (
          this.$store.getters.getPermissions.indexOf(
            menu.guard + "." + "view"
          ) !== -1
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import "../../assets/scss/main";
.sidebar-01-list>li.hasSub .card .active {
  color: rgb(255, 255, 255);
  font-weight: 600;
}
</style>
