<template>
    <flex-layout>
        <InvestorRelationCategoryModal />
        <page-header heading="Investor Relation Category" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <InvestorRelationCategoryTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import InvestorRelationCategoryTable from "@/components/InvestorRelationCategory/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import InvestorRelationCategoryModal from "@/components/InvestorRelationCategory/modal.vue";
export default {
    name: "InvestorRelationCategory",
    components: {
        PageHeader,
        FlexLayout,
        InvestorRelationCategoryTable,
        InvestorRelationCategoryModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Parent",
                },

                {
                    heading: "Status",
                },
            ],
        };
    },

    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        }
    }
};
</script>
  