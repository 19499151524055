<template>
    <flex-layout>
        <UncollectedCashDividendCheckModal :companies="companies" />
        <UncollectedCashDividendCheckDataModal :companies="companies" />
        <DeleteModal/>
        <page-header heading="Uncollected Cash Dividend Check" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm">
            <template v-slot:example>

                <div class="dropdown">
                    <button class="btn btn-outline-warning dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        Template
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li> <vue-excel-xlsx class="btn btn-sm btn-outline-red col-xl-12" :data="delete_uncollected.data"
                                :columns="delete_uncollected.columns" file-name="deleteduncollectedtemplate"
                                :file-type="'xlsx'" sheet-name="UncollectedCheckDeletion">
                                <i class="ic-delete" /> Delete
                            </vue-excel-xlsx></li>

                        <li>
                            <vue-excel-xlsx class="btn btn-sm btn-outline-green col-xl-12"
                                :data="uncollected_cash_dividend_check.data"
                                :columns="uncollected_cash_dividend_check.columns"
                                file-name="uncollectedcashdividendchecktemplate" :file-type="'xlsx'"
                                sheet-name="UncollectedCheckTemplate">
                                <i class="ic-download" /> Upload
                            </vue-excel-xlsx>

                        </li>

                    </ul>
                </div>

                <button class="btn btn-sm btn-outline-red" data-bs-toggle="offcanvas"
                    data-bs-target="#deleteuncollectcashmodal" aria-controls="offcanvasCreate">
                    Delete
                    <i class="ic-delete"></i>
                </button>

            </template>
            <template v-slot:export>
                <a :href="uri + '/export'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
            </template>

        </page-header>

        <UncollectedCashDividendCheckTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import UncollectedCashDividendCheckTable from "@/components/UncollectedCashDividendCheck/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import UncollectedCashDividendCheckModal from "@/components/UncollectedCashDividendCheck/modal.vue";
import UncollectedCashDividendCheckDataModal from "@/components/UncollectedCashDividendCheck/datamodal.vue";
import DeleteModal from "@/components/UncollectedCashDividendCheck/deletemodal.vue";

export default {
    name: "UncollectedCashDividendCheck",
    components: {
        PageHeader,
        FlexLayout,
        UncollectedCashDividendCheckTable,
        UncollectedCashDividendCheckModal,
        UncollectedCashDividendCheckDataModal,
        DeleteModal

    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'uncollected-cash-dividend-check',
            companies: {},
            tableHeading: [
                {
                    heading: "Company",
                },
                {
                    heading: "BOID",
                },
                {
                    heading: "Net Dividend Amount",
                },
                {
                    heading: "Fiscal Year",
                },
                {
                    heading: "Holding Kitta",
                },
                {
                    heading: "Bonus Rate",
                }
            ],

            uncollected_cash_dividend_check: {
                columns: [
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "name",
                        field: "name",
                    },
                    {
                        label: "fiscal_year",
                        field: "fiscal_year",
                    },
                    {
                        label: "holding_kitta",
                        field: "holding_kitta",
                    },
                    {
                        label: "bonus_rate",
                        field: "bonus_rate",
                    },
                    {
                        label: "net_div_amt",
                        field: "net_div_amt",
                    },
                    {
                        label: "remarks",
                        field: "remarks",
                    }
                ],
                data: [
                    {
                        boid: '1111111111111111',
                        name: 'FirstName LastName',
                        fiscal_year: '2068/69',
                        holding_kitta: '55152',
                        bonus_rate: 'bonus',
                        net_div_amt: 255,
                        remarks: 'remarks'
                    },
                ],


            },
            delete_uncollected: {
                columns: [
                    {
                        label: "company_id",
                        field: "company_id",
                    },
                    {
                        label: "boid",
                        field: "boid",
                    },
                    {
                        label: "fiscal_year",
                        field: "fiscal_year",
                    },
                ],
                data: [
                    {
                        company_id: '1',
                        boid: '1111111111111111',
                        fiscal_year: '2068/69',
                    },
                ]
            }
        };
    },
    async created() {
        let url = process.env.VUE_APP_API_URL + 'company/uncollected-cash-dividend-check';
        let response = await this.axios.get(url);
        this.companies = response.data;
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  <!--  -->