<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">View Complaint</h5>
            <div class="badge rounded-pill" :class="{ 'bg-warning': !item.status, 'bg-green': item.status }">
                {{ item.status ? "Resolved by: " + item.resolved_by : "Pending" }}
            </div>


            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-12 mb-16">
                        <label for="">Name</label>
                        <input type="text" class="form-control" v-model="item.name" disabled />
                    </div>
                    <div class="col-md-12 mb-16">
                        <label for="">Email</label>
                        <input type="text" class="form-control" v-model="item.email" disabled />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Subject</label>
                        <input type="text" class="form-control" v-model="item.subject" disabled />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Contact Number</label>
                        <input type="text" class="form-control" v-model="item.contact_number" disabled />
                    </div>

                    <div class="col-md-12 mb-16">
                        <label for="">Message</label>
                        <textarea name="message" class="form-control" v-model="item.message" disabled>
                        </textarea>
                    </div>


                    <hr>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <template v-if="!item.status">
                            <button v-if="!isLoading" class="btn btn-green" type="button" @click="setResolve()">
                                <i class="ic-checkmark mr-8"></i>Mark as Resolved
                            </button>
                            <button v-else class="btn btn-green" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </button>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
  
<script>
import CreateMixin from "@/mixins/create.vue"
import { eventbus } from "../../plugins/eventbus";

export default {
    name: "FAQModal",
    mixins: [CreateMixin],
    data() {
        return {
            url: 'complaint',
            fetch_module: 'FETCH_COMPLAINTS'
        };
    },
    computed: {

    },
    mounted() {
        this.$root.$on('EDIT', (data) => {
            this.item = { ...data };
        });

    },
    methods: {

        setResolve() {
            let uri = this.api_url + this.url + '/update';
            this.isLoading = true;

            this.axios.post(uri, { id: this.item.id }).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.$refs.btnClose.click();
                this.$root.$emit(this.fetch_module);
                eventbus.$emit('update-notifications')

            }).catch(() => {
                this.$toast.error("Something went wrong. Please Try Again.", {
                    position: "bottom-right",
                });
            });



        }


    },
};
</script>
  
<style scoped>

</style>
  