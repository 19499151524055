<template>
    <flex-layout>
        <page-header heading="Expired Demat User">
            <template v-slot:export>
                <a :href="uri + '/exportExpiredUsers'">
                    <button class="btn btn-sm btn-outline-danger"><i class="ic-checklist"></i>Export</button>
                </a>
                <button class="btn btn-sm btn-outline-green" @click="notifyAll()"><i class="ic-email"></i>Notify
                    All</button>

            </template>

        </page-header>
        <ExpiredDematUserTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import ExpiredDematUserTable from "@/components/ExpiredDematUser/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";

export default {
    name: "DematUserCheck",
    components: {
        PageHeader,
        FlexLayout,
        ExpiredDematUserTable,
    },
    data() {
        return {
            uri: process.env.VUE_APP_API_URL + 'demat-user',
            companies: {},
            tableHeading: [
                {
                    heading: "BOID",
                },
                {
                    heading: "Name"
                },
                {
                    heading: "Email"
                },

                {
                    heading: "Contact Number"
                },
                {
                    heading: "Created On"
                },
                {
                    heading: "Renewed On"
                },
                {
                    heading: "Expires On"
                },
            ],

        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
        notifyAll() {
            this.axios.post(this.uri + '/sendalert').then((response) => {
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });

                this.$root.$emit(this.fetch_module);

            });
        }
    }
};
</script>
