<template>
    <flex-layout>
        <FaqModal/>
        <page-header heading="Faq" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <FaqTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import FaqTable from "@/components/Faq/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import FaqModal from "@/components/Faq/modal.vue";
export default {
    name: "Faq",
    components: {
        // AbsoluteLayout,
        PageHeader,
        FlexLayout,
        FaqTable,
        FaqModal
    },
    data() {
        return {
            tableHeading: [
                {
                    heading: "Title",
                },
                {
                    heading: "Category",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },
    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },
    }
};
</script>
  