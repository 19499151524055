<template>
    <flex-layout>
        <SchemeModal :schemes="schemes" />
        <page-header heading="Scheme Prospectus" action="Add" toggle="offcanvas" target="#offcanvasCreate"
            :guard="this.$route.meta.guard" @click="setCreateForm" />

        <SchemeTable :tableHeading="tableHeading" />
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import SchemeTable from "@/components/MutualFund/Prospectus/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import SchemeModal from "@/components/MutualFund/Prospectus/modal.vue";
export default {
    name: "SchemeProspectus",
    components: {
        PageHeader,
        FlexLayout,
        SchemeTable,
        SchemeModal,
    },
    data() {
        return {
            schemes: [],
            tableHeading: [
                {
                    heading: "Scheme",
                },
                {
                    heading: "Title",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },

    async created() {
        let url = process.env.VUE_APP_API_URL + 'mutual-fund-scheme/getSchemes';
        let { data } = await this.axios.get(url);
        this.schemes = data;
    },


    methods: {
        setCreateForm() {
            this.$root.$emit("CREATE");
        },


    },
};
</script>
  