<template>
    <flex-layout>
        <MFDetailModal :menus="menus"/>
        <page-header heading="Mutual Fund Detail Content" action="Add" toggle="offcanvas" target="#offcanvasCreate" :guard="this.$route.meta.guard"
            @click="setCreateForm" />

        <MFDetailTable  :tableHeading="tableHeading"/>
    </flex-layout>
</template>
  
<script>
import FlexLayout from "@/components/Layout/FlexLayout.vue";
import MFDetailTable from "@/components/MutualFund/Detail/table.vue";
import PageHeader from "@/components/Utility/PageHeader.vue";
import MFDetailModal from "@/components/MutualFund/Detail/modal.vue";
export default {
    name: "MFDetailContent",
    components: {
        PageHeader,
        FlexLayout,
        MFDetailTable,
        MFDetailModal
    },
    data() {
        return {
           menus: {},
            tableHeading: [
               
                {
                    heading: "Menu",
                },
                {
                    heading: "Description",
                },
                {
                    heading: "File",
                },
                {
                    heading: "Status",
                },
            ],
        };
    },

    async created(){
        this.fetchListingMenus();
    },

    methods: {
        setCreateForm() {
            this.$root.$emit('CREATE');
        },

        async fetchListingMenus() {
            let url = process.env.VUE_APP_API_URL + 'mutual-fund-menu/getMenus/1';
            let {data} = await this.axios.get(url);
            this.menus = data.menus;
        }
    }
};
</script>
  