<template>
  <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
    aria-labelledby="offcanvasCreateLabel">
    <div class="offcanvas-header">
      <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Menu</h5>
      <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close" ref="btnClose">
        <i class="ic-close"></i>
      </button>
    </div>
    <div class="offcanvas-body">
      <div class="row">
        <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="col-md-12 mb-16">
            <label for="">Title</label>
            <input type="text" class="form-control" v-model="item.title" @input="createSlug" />
            <ErrorSpan :error="errors.title" />
          </div>
          <div class="col-md-12 mb-16">
            <label for="">Slug</label>
            <input type="text" class="form-control" v-model="item.slug" />
            <ErrorSpan :error="errors.slug" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Scheme</label>
            <select name="scheme_id" class="form-select" :class="{ 'border-red': errors.scheme_id }"
              v-model="item.scheme_id">
              <option value="" selected>Select Fund</option>
              <option v-for="(type, key) in schemes" :key="key" :value="type.id">{{ type.title }}</option>
            </select>
            <ErrorSpan :error="errors.scheme_id" />
          </div>


          <div class="col-md-12 mb-16">
            <label for="">Page Type</label>
            <select name="page_type" class="form-select" :class="{ 'border-red': errors.page_type }"
              v-model="item.page_type">
              <option value="0" selected>Select Type</option>
              <option value="1">Detail</option>
              <option value="2">Listing</option>
            </select>
            <ErrorSpan :error="errors.page_type" />
          </div>

          <div class="col-md-6 mb-16">
            <label for="">Display Order</label>
            <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
              class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
            <ErrorSpan :error="errors.order_by" />
          </div>

          <div class="col-md-6 mb-16">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault1" v-model="item.status" />
              <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
            </div>
          </div>
          <hr />
          <div class="col-md-12">
            <button type="button" aria-label="Close" data-bs-dismiss="offcanvas" class="btn btn-red mr-16">
              <i class="ic-close mr-8"></i>Cancel
            </button>
            <button v-if="!isLoading" class="btn btn-green" type="submit">
              <i class="ic-checkmark mr-8"></i>Save
            </button>
            <button v-else class="btn btn-green" type="button" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue";
import ErrorSpan from "@/components/errorspan.vue";
import SlugMixin from "@/mixins/SlugMixin.vue";

export default {
  name: "MFMenuModal",
  mixins: [CreateMixin, SlugMixin],
  components: { ErrorSpan },
  props: ['schemes'],
  data() {
    return {
      url: "mutual-fund-menu",
      mode: "",
      fetch_module: "FETCH_MUTUAL_FUND_MENUS",
    };
  },
  mounted() {
    this.$root.$on("CREATE", () => {
      this.isEdit = false;
      this.item = {
        status: 0,
      };
      this.errors = {};
      this.mode = "Add";
    });

    this.$root.$on("EDIT", (data) => {
      this.isEdit = true;
      this.item = { ...data };
      this.mode = "Edit";
      this.errors = {};
    });
  },

};
</script>

