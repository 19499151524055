<template>
    <div class="offcanvas offcanvas-end offcanvas-01" id="offcanvasCreate" tabindex="-1"
        aria-labelledby="offcanvasCreateLabel">
        <div class="offcanvas-header">
            <h5 class="text-dark" id="offcanvasCreateLabel">{{ mode }} Notice</h5>
            <button class="btn btn-danger btn-sm" type="button" data-bs-dismiss="offcanvas" aria-label="Close"
                ref="btnClose">
                <i class="ic-close"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row">
                <form class="row align-end" v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                    <div class="col-md-6 mb-16">
                        <label for="">Title</label>
                        <input type="text" class="form-control" :class="{ 'border-red': errors.title }" v-model="item.title"
                            @input="createSlug" />
                        <ErrorSpan :error="errors.title" />
                    </div>
                    <div class="col-md-6 mb-16">
                        <label for="">Slug</label>
                        <input type="text" class="form-control" :class="{ 'border-red': errors.slug }"
                            v-model="item.slug" />
                        <ErrorSpan :error="errors.slug" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Category</label>
                        <treeselect v-model="value" :multiple="true" :options="options" :flat="true" />
                        <ErrorSpan :error="errors.notice_category_id" />
                    </div>

                    <div class="row mb-16">
                        <div class="col-md-6 "></div>
                        <label for="">Image</label>
                        <input type="file" class="form-control" ref="image" @input="handleImageUpload" />
                        <div class="col-md-6 mt-16" v-if="upload.status == false">
                            <UploadedImg :file='(storage + item.image)' :clearUploadedFile="clearUploadedImage"
                                v-if="(mode === 'Edit' && item.image)" />
                        </div>
                        <div class="col-md-6 mt-16" v-else>
                            <img :src="upload.imgPreview" alt="" height="100" width="100">
                        </div>
                        <ErrorSpan :error="errors.image" />
                    </div>

                    <div class="row mb-16">
                        <div class="col-md-6 ">
                            <label for="">File</label>
                            <UploadedFile :file='storage + item.file' :clearUploadedFile="clearUploadedFile"
                                v-if="mode === 'Edit' && item.file" />
                            <input type="file" class="form-control" ref="file" @input="handleFileUpload" v-else />
                        </div>
                        <ErrorSpan :error="errors.file" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Scheduled on</label>
                        <input type="date" class="form-control" v-model="item.scheduled_on" />
                        <ErrorSpan :error="errors.scheduled_on" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <label for="">Display Order</label>
                        <input type="number" min="1" @input="item.order_by = item.order_by < 1 ? '' : item.order_by"
                            class="form-control" :class="{ 'border-red': errors.order_by }" v-model="item.order_by" />
                        <ErrorSpan :error="errors.order_by" />
                    </div>

                    <div class="col-md-6 mb-16">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" true-value="1" false-value="0"
                                v-model="item.status" />
                            <label class="form-check-label" for="flexSwitchCheckDefault">Status</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="button" aria-label="Close" @click="closeForm" class="btn btn-red mr-16">
                            <i class="ic-close mr-8"></i>Cancel
                        </button>
                        <button v-if="!isLoading" class="btn btn-green" type="submit">
                            <i class="ic-checkmark mr-8"></i>Save
                        </button>
                        <button v-else class="btn btn-green" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import CreateMixin from "@/mixins/create.vue"
import UploadPreviewMixin from "@/mixins/uploadpreview.vue";
import UploadedFile from "@/components/Utility/UploadedFile.vue";
import UploadedImg from "@/components/Utility/UploadedImg.vue";
import ErrorSpan from "@/components/errorspan.vue";
import MediasMixin from "@/mixins/MediasMixin.vue";
import SlugMixin from "@/mixins/SlugMixin.vue";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
    name: "NoticeModal",
    components: {
        UploadedImg,
        UploadedFile,
        ErrorSpan,
        Treeselect
    },
    mixins: [CreateMixin, UploadPreviewMixin, MediasMixin, SlugMixin],
    data() {
        return {
            url: 'notice',
            fetch_module: 'FETCH_NOTICES',
            categories: [],
            value: [],
            options: [],
            notice_categories: []
        };
    },

    mounted() {
        this.axios.get(process.env.VUE_APP_API_URL + this.url + '/categories').then(({ data }) => {
            this.categories = data.categories;

            this.options = this.categories.map((item) => ({
                id: item.id,
                label: item.title,
            }));
        });
        this.$root.$on('CREATE', () => {
            this.item = {};
            this.isEdit = false;
            this.mode = "Add";
            this.errors = {};
            this.value = [];
            this.clearselectedFiles();

        });

        this.$root.$on('EDIT', (data) => {
            this.errors = {};
            this.value = [];
            this.isEdit = true;
            this.item = { ...data };
            this.mode = "Edit";
            this.clearselectedFiles();

            this.value = this.item.notice_category.map((item) => item.id);
        });

    },
    methods: {
        closeForm() {
            this.$refs.btnClose.click();
        },
        submitForm() {

            let uri = this.api_url + this.url + (this.isEdit ? '/update' : '/store');
            this.isLoading = true;

            let formDatas = new FormData();
            formDatas.append('id', this.item.id);

            if (this.item.title) {
                formDatas.append('title', this.item.title);
            }

            if (this.item.slug) {
                formDatas.append('slug', this.item.slug);
            }

            if (this.item.description) {
                formDatas.append('description', this.item.description);
            }
            if (this.item.scheduled_on) {
                formDatas.append('scheduled_on', this.item.scheduled_on);
            }
            if (this.item.order_by) {
                formDatas.append('order_by', this.item.order_by);
            }
            if (this.item.status) {
                formDatas.append('status', this.item.status);
            }

            formDatas.append('notice_category_id', this.value);

            if (this.item.image) {
                formDatas.append('image', this.item.image);
            }

            if (this.item.file) {
                formDatas.append('file', this.item.file);
            }

            this.axios.post(uri, formDatas, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then((response) => {
                this.isLoading = false;
                this.$toast.success(response.data.message, {
                    position: "bottom-right",
                });
                this.$refs.btnClose.click();
                this.$root.$emit(this.fetch_module);
            }).catch((error) => {
                this.isLoading = false;
                if (error.response) {
                    if (error.response.status == 422) {
                        this.pushErrors(error.response.data.errors);
                        this.$toast.error("Please check the form again.", {
                            position: "bottom-right",
                        });
                    }
                }
            });
        },
    },
};
</script>

<style scoped></style>
